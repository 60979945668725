import React, { Component } from 'react';

import './ListBox.css';
import SvgIcon from '../svg-icon/SvgIcon';

class ListBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: props.items || [],
            activeId: props.activeId || ''
        };
        this.itemSelected = this.itemSelected.bind(this);
        this.openTerminal = this.openTerminal.bind(this);
        this.handlePlayingClick = this.handlePlayingClick.bind(this);
        this.handleReloadClick = this.handleReloadClick.bind(this);
    }

    itemSelected(event) {
        this.props.updateList(event.target.id);
    }

    openTerminal(url) {
        console.log(url);
    }

    handlePlayingClick(item) {
        if (this.props.handleClick) {
            this.props.handleClick(item);
        }

    }

    handleReloadClick(item) {
        if (this.props.handleReload) {
            this.props.handleReload(item);
        }

    }

    showTerminalButton(item) {
        if (item.terminal) {
            return (
                <div className='col-2 p-0 max-width-44px'>
                    <a className={`ListBox-li d-inline-block text-center ${item.itemId === this.props.activeId ? "active":""}`}
                        href={item.terminal} target="_blank" rel="noopener noreferrer">
                        <SvgIcon srcIcon="display" width="15" height="15" fill={"black"}/>
                    </a>
                </div>
            );
        }
        return null;
    }

    showReloadButton(item) {
        if (item.status) {
            return (
                <div className='col-2 p-0 max-width-44px'>
                    <button className={`ListBox-li d-inline-block text-center ${item.itemId === this.props.activeId ? "active":""}`}
                        onClick={() => {this.handleReloadClick(item.itemId)}}>
                        <SvgIcon srcIcon="reload" width="15" height="15" fill={"green"} title="Reiniciar"/>
                    </button>
                </div>
            );
        }
        return null;
    }

    showPlaying(item) {
        if (item.status) {
            return (
                <div className='col-2 p-0 max-width-44px'>
                    <button className={`ListBox-li d-inline-block text-center ${item.itemId === this.props.activeId ? "active":""}`}
                        onClick={() => {this.handlePlayingClick(item.itemId)}}>
                        <SvgIcon srcIcon="music" width="15" height="15" fill={"green"}/>
                    </button>
                </div>
            );
        }
        return null;
    }

    render() {
        const listItems = this.state.items.map((item) => {
            let iconFill = item.status ? 'green':'red'
            return (
                <div className="row m-0" key={item.itemId}>
                    <div className='col'>
                        <button className={`ListBox-li d-flex align-items-center justify-content-between ${item.itemId === this.props.activeId ? "active":""}`}
                            id={item.itemId}
                            onClick={(event) => {this.itemSelected(event)}}>
                            {item.itemId} {item.name}
                            <SvgIcon srcIcon="bulb" width="15" height="15" fill={iconFill}/>
                        </button>

                    </div>
                    {
                        this.showPlaying(item)
                    }
                    {
                        this.showTerminalButton(item)
                    }
                    {
                        this.showReloadButton(item)
                    }

                </div>
            )
        }

        );
        return (
            <div className="ListBox-container h-100">
                <div className="ListBox-item h-100">{listItems}</div>
            </div>
        );
    }
}

export default ListBox;
