import React, { Component } from 'react';
import './DevicesCustomer.css';
import ConnectionsService from '../../../../core/services/ConnectionsService';
import AuthService from '../../../../core/services/AuthService';

import { ItemFilter } from '../../../../core/helpers';
import { Redirect } from 'react-router-dom';

import Searcher from '../../../shared/searcher/Searcher';
import ListBox from '../../../shared/list-box/ListBox';
import Dialog from '../../../shared/dialog/Dialog';

import SvgIcon from '../../../shared/svg-icon/SvgIcon';

import defaultThumbnail from '../../../../assets/images/icons/icn_video_default_thumbnail.png';
import ReactPaginate from 'react-paginate';
import ReactPlayer from 'react-player';
import swal from '@sweetalert/with-react';
import { store } from 'react-notifications-component';

class DevicesCustomer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            devices: [],
            deviceInfo: [],
            activeGo: false,
            goId:'',
            showPlayer: false,
            showPlayerVid: false,
            devicePlaying: {},
            deviceLog: [],
            perPage: 30,
            currentPage: 0,
            pageCount: 0,
            totalLogs: 0,
            activeDevice: { itemId: '', name: '', playlists: []},
            playingUrl: '',
            playingName: '',
            playingIndex: 0,
            playlist: { videos: []},
            pickedList: {},
            perPageVid: 50,
            currentPageVid: 0,
            pageCountVid: 0,
            totalVideos: 0,
            placeholderVideos: 'Esta lista aún no tiene videos asignados'
        }

        this.deviceInfo = [];

        this.getDevices = this.getDevices.bind(this);
        this.listClicked = this.listClicked.bind(this);
        this.dynamicSort = this.dynamicSort.bind(this);
        this.handleSignout = this.handleSignout.bind(this);
        this.showPlayingModal = this.showPlayingModal.bind(this);
        this.hidePlayer = this.hidePlayer.bind(this);
        this.getDeviceLog = this.getDeviceLog.bind(this);
        this.clickChild = this.clickChild.bind(this);

        this.handlePageClick = this.handlePageClick.bind(this);
        this.handlePageClickVid = this.handlePageClickVid.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.deleteChild = this.deleteChild.bind(this);

        this.intervalId  = null;
    }

    componentDidMount() {
        this.getDevices("");
    }

    showPlayingModal(itemId) {
        let device = ItemFilter(this.state.devices, 'itemId', itemId);

        if (device.length) {
            this.getDeviceLog(itemId, (result) => {
                if (result) {
                    this.setState({devicePlaying: device[0]},() => {
                        this.setState({showPlayer: true});
                        this.intervalId  = setInterval(this.getDeviceLog, 60000);
                    })
                }
            })
        }
    }

    handlePageClick = (data) => {
        this.setState({ currentPage: data.selected }, () => {
            this.getDeviceLog();
        });
    }

    handlePageClickVid = (data) => {
        if (this.state.pickedList !== {}) {
            this.setState({ currentPageVid: data.selected}, () => {
                //this.getPlayListById(this.state.pickedList.id);
            });
        }
    }

    hidePlayer() {
        clearInterval(this.intervalId);
        this.setState({
            showPlayer: false,
            devicePlaying: {},
            deviceLog: []
        });
    }

    getPlayListById(id: String) {

        this.setState({playlist: { videos: []}}, () => {

            ConnectionsService.get(`customer/playlist/${id}`)
            .then((response) => {

                let pageCountVid = 0;
                let totalVideos = 0;

                if (response.data) {
                    if (response.data.video_count > 0){
                        pageCountVid = Math.ceil(response.data.video_count / this.state.perPageVid)
                        totalVideos = response.data.video_count;
                    }
                    this.setState({playlist: response.data, pageCountVid, totalVideos});
                }

            });

        });

    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }

    }

    getDevices(text) {
        let placeholderVideos = "Cargando...";
        this.setState({
            devices:[],
            placeholderVideos,
            playlist: { videos: []},
            pickedList: {},
            activeDevice: { itemId: '', name: '', playlists: []}
        }, () => {

            let searchTerm = text.trim() !== "" ? `?s=${text}`:'';

            ConnectionsService.get(`customer/device${searchTerm}`).then((response) => {
                if(response.data && response.data.length) {
                    response.data.forEach((device) => {
                        device.itemId = device.id.toString();
                    });
                }
                const devices = response.data.map((obj) => {
                    obj['status'] = obj['socket_id'] ? true:false;

                    if (obj.playlists.length) {
                        obj.playlists = obj.playlists.filter(play => play !== null);
                    }
                    return obj;
                });
                let activeDevice = { itemId: '', name: '', playlists: []};

                if(devices.length) {
                    activeDevice = devices[0];
                }

                let placeholderVideos = devices.length ? this.state.placeholderVideos:"Sin resultados";

                this.setState({devices, activeDevice, placeholderVideos}, () => {
                    if (this.state.devices.length && this.state.devices[0].playlists.length) {

                        this.setState({pickedList: this.state.devices[0].playlists[0]}, () => {
                            this.getPlayListById(this.state.devices[0].playlists[0].id);
                        });

                    }
                });
            }, (error) => {
                if(error.response.data) {
                    if(!error.response.data.auth){
                        this.handleSignout();
                    }
                }
            });

        });

    }

    handleSignout() {
        AuthService.handleSignout();
        window.location.href = '/login';
    }

    fixSchedules(obj) {
        let array = [];
        if(obj.length) {
            for(let i = 1; i <= 7; i++) {
                let items = ItemFilter(obj, 'day_week', i);
                if(items.length) {
                    let hour1 = ItemFilter(items, 'start_time', "14:00");
                    let hour2 = ItemFilter(items, 'start_time', "16:00");
                    let hour3 = ItemFilter(items, 'start_time', "21:00");

                    if(hour1.length) {
                        array.push(hour1[0]);
                    }
                    if(hour2.length) {
                        array.push(hour2[0]);
                    }
                    if(hour3.length) {
                        array.push(hour3[0]);
                    }
                }
            }
        }
        return array;
    }

    listClicked (id) {
        let activeDevice = this.state.devices.filter(device => device.itemId === id );

        if (activeDevice.length) {
            this.setState({activeDevice: activeDevice[0]}, () => {
                if (activeDevice[0].playlists.length) {
                    this.getPlayListById(activeDevice[0].playlists[0].id);
                }

            });
        }
    }

    dynamicSort(property) {
        let sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return (a,b) => {
            let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    getDeviceLog(id, callBack) {
        let idDevice = id ? id:this.state.devicePlaying.itemId;

        ConnectionsService.get(`customer/device/${idDevice}/log?page=${this.state.currentPage + 1}`).then((response) => {
            let pageCount = 0;
            let totalLogs = 0;

            if (response.status === 200 && response.data.log.length) {
                if (response.data.total_items > 0){
                    pageCount = Math.ceil(response.data.total_items / this.state.perPage)
                    totalLogs = response.data.total_items;
                }
                this.setState({deviceLog: response.data.log, pageCount, totalLogs},() => {
                    if (callBack) {
                        callBack(true);
                    }
                });
            } else {
                this.setState({pageCount, totalLogs},() => {
                    if (callBack) {
                        callBack(false);
                    }
                });
            }
        });
    }

    showPlayerModal(event, playlist) {
        //this.setState({playingUrl: '', playingName: '', playingIndex: 0});

        // this.setState();
        // this.getVideosById(id, () => {
        //     if(this.state.videos.length && this.state.videos[0].filename){
        //         let title = this.state.videos[0].title !== this.state.videos[0].autor ? `${this.state.videos[0].autor} - ${this.state.videos[0].title}`:this.state.videos[0].autor;
        //         this.setState({
        //             playingName: title,
        //             playingUrl: this.state.videos[0].filename,
        //             showPlayer: true,
        //             playingIndex: 0
        //         });
        //     }
        // });
    }

    clickChild(video) {

        if (video && video.filename && video.title) {
            this.setState({
                showPlayerVid: true,
                playingUrl: video.filename,
                playingName: video.title
            });
        }

    }

    handleSelect(e) {
        if(e.target.value) {
            let list = this.state.activeDevice.playlists.filter(playlist => playlist.id === parseInt(e.target.value));

            if ( list.length) {
                this.setState({pickedList: list[0]}, () => {
                    this.getPlayListById(list[0].id);
                });
            }

        } else {
            this.setState({playlist: { videos: []}, pickedList: {name: '', id: ''}});
        }
    }

    buildOptions () {
        let arr = [];

        if (this.state.activeDevice.playlists.length) {
            this.state.activeDevice.playlists.forEach( (playlist, index) => {
                arr.push(<option key={index} value={playlist.id}>{playlist.name}</option>)
            })
        }

        return arr;
    }

    handlePageClick = (data) => {
        this.setState({ currentPage: data.selected });
    }

    // getPlayListById(id: String) {
    //
    //     this.setState({playlist: { videos: []}}, () => {
    //
    //         ConnectionsService.get(`customer/playlist/${id}`)
    //         .then((response) => {
    //
    //             let pageCountVid = 0;
    //             let totalVideos = 0;
    //
    //             if (response.data) {
    //                 if (response.data.video_count > 0){
    //                     pageCountVid = Math.ceil(response.data.video_count / this.state.perPageVid)
    //                     totalVideos = response.data.video_count;
    //                 }
    //                 this.setState({playlist: response.data, pageCountVid, totalVideos});
    //             }
    //
    //         });
    //
    //     });
    //
    // }

    deleteChild(event, id) {
        swal({
            title: "¿Estás seguro?",
            icon: "warning",
            buttons: ["No borrar!", "Borrar!"],
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                ConnectionsService.delete(`customer/device/${id}`)
                    .then((response) => {
                        let playlist = this.state.playlist;

                        if (playlist && playlist.videos && playlist.videos.length) {
                            let video = playlist.videos.filter(element => element.pv_id === id);

                            if (video.length) {
                                let index = playlist.videos.indexOf(video[0]);

                                if (index >= 0) {
                                    playlist.videos.splice(index,1);
                                    this.setState({playlist});
                                }
                            }
                        }
                        store.addNotification({
                            title: "Listo!",
                            message: "Video eliminado correctamente",
                            type: "success",
                            insert: "bottom",
                            container: "bottom-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 1000,
                                onScreen: true
                            }
                        });
                    }, (e) => {
                        store.addNotification({
                            title: "Error!",
                            message: "Error al intantar eliminar video",
                            type: "danger",
                            insert: "bottom",
                            container: "bottom-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 1000,
                                onScreen: true
                            }
                        });
                    });
            }
        });
    }

    render() {

        const showList = this.state.deviceLog.length ? this.state.deviceLog.map((log, index) => {
            return (
                <div className={`row m-0 p-2 ${index === 0 && this.state.currentPage === 0 ? 'on-playing-row':''}`} key={index}>
                    <div className="col-12 p-0">
                        <div className='row m-0 pb-2'>
                            <div className="col max-width-50px p-2">

                                <img src={defaultThumbnail} alt="" width="50"/>
                            </div>
                            <div className="col pr-0">
                                <span className="font-size-12 playing-title">{log.video.autor.length ? `${log.video.autor} - `:''}{log.video.title} (Lista: {log.playlist.name})</span>
                                { index === 0 && this.state.currentPage === 0 && (<span className="font-size-10 font-color-fff"><br/>Reproduciendo</span>)} <br/>
                                {
                                    log.video.tags.length ? log.video.tags.map(tag => <span key={`tag-${tag.name}-${index}`} className="DevicesCustomer-tag-chip">{tag.name}</span>):(null)
                                }
                            </div>
                            <div className="col-4 pr-0">
                                <span className={`font-size-10 ${index === 0 && this.state.currentPage === 0 ? 'font-color-fff':''}`}>
                                    Número de pista: {log.num_track}
                                </span>
                                <br/>
                                <span className={`font-size-10 ${index === 0 && this.state.currentPage === 0 ? 'font-color-fff':''}`}>
                                    Total track: {log.total_track}
                                </span>
                                <br/>
                                <span className={`font-size-10 ${index === 0 && this.state.currentPage === 0 ? 'font-color-fff':''}`}>
                                    Fecha de reproducción: {log.created_at}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }):(null);

        const tagsList = (tags) => {
            const items = tags.map((tag, index) => <span className="TableItem-chip" key={index}>{tag.name}</span>);
            return (<div className="col-12">{items}</div>)
        }

        const listVideos = this.state.playlist.videos.length ? this.state.playlist.videos.map((video, index) => {

            return (
                    <div className="col-12" key={index}>
                        <div className="row m-0">

                            <div className="col-12 DevicesCustomer-row-active margin-bottom-10">
                                <div className="row m-0 h-100 d-flex align-items-center pr-3">
                                    <div className="col max-width-50px">
                                        <SvgIcon srcIcon="playlist" width="35px"/>
                                    </div>
                                    <div className="col">
                                        <div className="row m-0 line-height-18px"> 
                                            <div className="col-12">
                                                <strong>
                                                    {video.title}
                                                </strong>
                                            </div>
                                            <div className="col-12">
                                                {video.autor}
                                            </div>
                                            {
                                                video.tags && video.tags.length && tagsList(video.tags)
                                            }
                                        </div>
                                    </div>
                                    <div className="col-1 pl-0 d-flex align-items-center max-width-70px">
                                        <button className="table-btn" onClick={() => {this.clickChild(video)}}>
                                            <SvgIcon srcIcon="play" width="35" height="35" fill="#7F60EA"/>
                                            <span className="table-btn-span">Reproducir</span>
                                        </button>
                                    </div>
                                    <div className="col-1 pl-0 d-flex align-items-center max-width-70px">
                                        <button className="table-btn" onClick={(e) => {this.deleteChild(e, video['pv_id'])}}>
                                            <SvgIcon srcIcon="close" width="35" height="35" fill="#7F60EA"/>
                                            <span className="table-btn-span">Eliminar</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
            )

        }):(null);
        // const listSchedules = this.state.activeDevice.playlists ? this.state.playlists.map((play,index) => {
        //     return (
        //         <div className="col-12" key={index}>
        //             <div className="row m-0">
        //                 { play.playlist.id !== 0  && (
        //                     <div className="col-12 DeviceEdit-row-active">
        //                         <div className="row m-0 h-100 d-flex align-items-center pr-3">
        //                             <div className="col max-width-50px">
        //                                 <SvgIcon srcIcon="playlist" width="35px"/>
        //                             </div>
        //                             <div className="col">
        //                                 <div className="row m-0">
        //                                     <div className="col-12">
        //                                         <strong>
        //                                             {play.playlist.name}
        //                                         </strong>
        //                                     </div>
        //                                     <div className="col-12">
        //                                         {play.playlist.count_videos >= 0 ? `${play.count_videos} videos`:(null)}
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                             <div className="col max-width-50px">
        //                                 <button className="table-btn" onClick={() => {this.clickChild(index)}}>
        //                                     <SvgIcon srcIcon="play" width="35" height="35" fill="#7F60EA"/>
        //                                     <span className="table-btn-span">Reproducir</span>
        //                                 </button>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 )}
        //
        //             </div>
        //         </div>
        // )
        // }):(null);

        // const listLists = this.state.devices.length && this.state.devices? this.state.lists.map(video =>
        //     <TableItem srcIcon="playlist"
        //         autor={video.count_videos ? `${video.count_videos} videos`:''}
        //         itemId={video.itemId}
        //         title={video.name}
        //         key={video.itemId}
        //         isChecked={video.isChecked}
        //         handleCheck={this.checkChild}
        //         handleItemClick={this.clickChild}>
        //         {
        //             video.count_videos > 0 && (<button className="table-btn"
        //                 id={`add-${video.itemId}`}
        //                 onClick={(e) => {this.showPlayerModal(e,video.itemId)}}
        //                 disabled={this.state.isAjax}>
        //                 <SvgIcon srcIcon="play" width="35" height="35" fill={this.state.iconFill}/>
        //                 <span className="table-btn-span">Reproducir</span>
        //             </button>)
        //         }
        //         <button className="table-btn"
        //             id={`add-${video.itemId}`}
        //             onClick={(e) => {this.editItem(e,video.itemId)}}
        //             disabled={this.state.isAjax}>
        //             <SvgIcon srcIcon="edit" width="35" height="35" fill={this.state.iconFill}/>
        //             <span className="table-btn-span">Editar</span>
        //         </button>
        //         <button className="table-btn"
        //             id={`delete-${video.itemId}`}
        //             onClick={(e) => {this.deleteChild(e,video.itemId)}}
        //             disabled={this.state.isAjax}>
        //             <SvgIcon srcIcon="close" width="35" height="35" fill={this.state.iconFill}/>
        //             <span className="table-btn-span">Eliminar</span>
        //         </button>
        //     </TableItem>
        // ):(null);
        //
        const listPlaylistPlaceholder = !this.state.playlist.videos.length ? (
            <div className="w-100 h-100 d-flex align-items-center justify-content-center pt-5">
                <h3>
                    {this.state.placeholderVideos}
                </h3>
            </div>
        ):(null);

        return (
            <div className="DevicesCustomer-container">

                <Dialog show={this.state.showPlayerVid}
                    onHide={() => this.setState({showPlayerVid: false})}
                    buttonTitle="Subir video"
                    buttonShow={false}
                    isUploading={false}>
                    <h4 className="text-center">{this.state.playingName}</h4>
                    <ReactPlayer controls={true} url={this.state.playingUrl} playing={this.state.showPlayerVid} width='100%' height='calc(100% - 50px)' />
                </Dialog>

                <Dialog show={this.state.showPlayer}
                    onHide={() => this.hidePlayer()}
                    buttonTitle="Subir video"
                    buttonShow={false}
                    isUploading={false}>


                    <div className="row m-0">
                        <div className="col-12 pt-3">
                            <h4>{this.state.devicePlaying.name}</h4>
                            <hr/>
                            <div className="row m-0 log-container">
                                <div className="col-12 p-0">
                                    {showList}
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.totalLogs > this.state.perPage ? (
                        <ReactPaginate previousLabel={'Anterior'}
                            nextLabel={'Siguiente'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={this.state.pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}/>
                    ):(null)}

                </Dialog>

                {
                    this.state.activeGo && <Redirect to={`/dispositivos-edit/${this.state.goId}`} />
                }
                <div className="row m-0 pb-4">
                    <div className="col-12 col-md p-0 w-100 d-flex align-items-center">
                        <h4 className="mb-0">
                            Dispositivos
                        </h4>
                    </div>

                    {
                        this.state.activeDevice.playlists ? (
                            <div className="col-12 col-md-2 p-0">
                                <span>Seleccionar lista </span>
                                <select className="h-100" value={this.state.pickedList.id} name="" id="" onChange={(e) => this.handleSelect(e)}>
                                    <option value="" disabled>Seleccionar lista</option>
                                    {this.buildOptions()}
                                </select>

                            </div>
                        ):(null)
                    }

                    <div className="col-12 col-md-3">
                        <Searcher updateInput={this.getDevices} placeholder="Buscar dispositivo"/>
                    </div>
                </div>

                <div className="row m-0 DevicesCustomer-box">

                    <div className="col-12 col-sm-4">
                        <div className="row m-0">
                            <div className="col-12 p-0">
                                <span>Seleccionar dispositivo</span>
                            </div>
                        </div>
                        {
                            this.state.devices.length ?
                                <ListBox items={this.state.devices}
                                    activeId={this.state.activeDevice.itemId}
                                    updateList={this.listClicked}
                                    handleClick={this.showPlayingModal}/>:(null)
                        }
                        {
                            !this.state.devices.length ?
                                <ListBox items={this.state.devices}
                                    activeId={"-1"}
                                    updateList={this.listClicked}
                                    handleClick={this.showPlayingModal}/>:(null)
                        }
                    </div>
                    <div className="col">
                        <div className="row DevicesCustomer-video-list">
                            { listVideos }
                            { listPlaylistPlaceholder }
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}
export default DevicesCustomer;
