import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { store } from 'react-notifications-component';

import Select from "react-select";
//import "react-select/dist/react-select.css";

import SvgIcon from '../svg-icon/SvgIcon';

import './DropVideo.css';

class DropVideo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            file:[],
            isFile : false,
            title: {
                touched: false,
                value: ''
            },
            autor: {
                touched: false,
                value: ''
            },
            formErrors: {
                title: '',
                autor: ''
            },
            videoTitle:'',
            titleValid: false,
            autorValid: false,
            formValid: false,
            tags: this.props.tags || [],
            multiValue: [],
            percentCompleted: null
        }

        this.data = {
            title: '',
            autor: '',
            file: [],
            tags:[]
        }
        this.handleInput = this.handleInput.bind(this);
        this.validateField = this.validateField.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.errorClass = this.errorClass.bind(this);
        this.handleSelect = this.handleSelect.bind(this);

        this.handleMultiChange = this.handleMultiChange.bind(this);


    }

    handleMultiChange(option) {
        if (option) {
            let tags = [];
            if (option.length) {
                option.forEach((op, i) => {
                    tags.push(op.value);
                });
            }
            this.setState({multiValue: option});
            this.data.tags = tags;

            this.props.childrenData(this.data);
        }
    }

    handleInput(event) {
        const key = event.target.name;
        let value = event.target.value;

        this.data[key] = value;
        this.setState(
            {[key]: { value: value, touched: true}},
            () => { this.validateField(key, value) }
        );
        this.props.childrenData(this.data);
    }

    tagsFilter(tags = []) {
        let tagsFiltered = [];

        tagsFiltered = tags.map((tag) => {
            return {value: tag.id, label: tag.name}
        });


        return tagsFiltered;
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let titleValid = this.state.titleValid;
        let autorValid = this.state.autorValid;
        let msg = '';
        switch(fieldName) {
            case 'title':
                titleValid = true;
                if(!value.length) {
                    msg = 'Título es requerido';
                    titleValid = false;
                }
                fieldValidationErrors.title = msg;
                break;
            case 'autor':
                autorValid = true;
                if(!value.length) {
                    msg = 'Autor es requerido';
                    autorValid = false;
                }
                fieldValidationErrors.autor = msg;
                break;
            default:
                break;
        }
        this.setState({formErrors: fieldValidationErrors, titleValid, autorValid}, this.validateForm);
    }

    validateForm() {
        this.setState({formValid: this.state.titleValid && this.state.autorValid});
    }

    errorClass(type) {
        return(this.state.formErrors[type].length === 0 || !this.state[type].touched ? '' : 'has-error');
    }

    getFile(file) {
        let msg = '';
        let error = false;
        const sizeLimit = 1000000000;
        let notificationConfig = {
            title: "Atención!",
            message: '',
            type: "warning",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 1000,
                onScreen: true
            }
        }
        if(file.length) {
            if(file[0].type === "video/mp4" || file[0].type === "video/x-matroska") {
                if(file[0].size <= sizeLimit) {
                    this.data.file = file;
                    this.setState({videoTitle: file[0].name});
                    this.setState({file});
                    this.setState({isFile: true});
                } else {
                    error = true;
                    msg = "Solo puedes subir videos de hasta 100 megas"
                }
            } else {
                msg = "Solo puedes subir videos del tipo mp4 ó mkv";
                error = true;
            }
            if (error) {
                notificationConfig.message = msg;
                store.addNotification(notificationConfig);
            }
        } else {
            notificationConfig.message = "Solo puedes cargar un video a la vez";
            store.addNotification(notificationConfig);
        }
    }

    buildTags (tags =  []) {
        let arr = [];

        tags.forEach((tag, i) => {
            arr.push(<option key={i} value={tag.id}>{tag.name}</option>);
        });

        return arr;
    }

    handleSelect(e) {
        // this.setState({[type]: e.target.value});
    }

    componentDidMount() {
        window.addEventListener('percentCompleted', () => {
            let percentCompleted = localStorage.getItem('percentCompleted');
            this.setState({percentCompleted});
        });
    }

    componentWillUnmount() {
        window.removeEventListener('percentCompleted', ()=>{});
    }

    render() {
        const showLoader = this.state.percentCompleted ? (
            <div className="col-12 DropVideo-loader-container">
                <div className="row m-0">
                    <div className="col-8 offset-2 p-0 DropVideo-loader-background">
                        <div className="DropVideo-loader-progress-bar" style={{width: this.state.percentCompleted ? this.state.percentCompleted+ "%":0 + "%"}}> &nbsp;</div>
                        <span className="DropVideo-loader-progress-text" >{this.state.percentCompleted ? this.state.percentCompleted:0}%</span>
                    </div>
                </div>
            </div>
        ):(null);

        const uploading = this.props.isUploading ? (
            <div className="w-100 h-100 d-flex aling-items-center justify-content-center row m-0">
                <div className="col-12 d-flex align-self-end justify-content-center">
                    <SvgIcon srcIcon="loading" className="drop-zone-loading-icon" width="50px"/>
                </div>
                <div className="col-12 d-flex align-self-start justify-content-center">
                    Subiendo tu video...
                </div>
                {showLoader}
            </div>
        ):(null);
        const showDropZone = !this.state.isFile && !this.props.isUploading ? (
            <Dropzone multiple={false} onDrop={acceptedFiles => this.getFile(acceptedFiles)}>
                {({getRootProps, getInputProps}) => (
                    <section className="w-100 h-100 d-flex align-items-center justify-content-center drop-zone-section">
                        <div {...getRootProps()}
                            className="text-center drop-zone-div row m-0 w-100 h-100 d-flex align-items-center justify-content-center">
                            <input {...getInputProps()} />
                            <div className="col-12 d-flex align-self-end justify-content-center">
                                <SvgIcon srcIcon="movielane" width="50px" height="50px"/>
                            </div>
                            <div className="col-10 d-flex align-self-start justify-content-center">
                                <p>Arrastra y suelta el video aquí para subirlos</p>
                            </div>
                        </div>
                    </section>
                )}
            </Dropzone>):(null);

        const showFileForm = this.state.isFile && !this.props.isUploading ? (
            <div className="col-12 col-sm-10">
                <div className="row m-0">
                    <div className="col-12 text-center">
                        <SvgIcon srcIcon="movielane" width="50px" height="50px"/>
                    </div>
                    <div className="col-12 text-center">
                        <strong>{this.state.videoTitle}</strong>
                    </div>
                </div>

                <div className="row m-0 py-3">
                    <div className="col-12 text-left">
                        <div className="form-group mb-4">
                            <label htmlFor="title">Título</label>
                            <input type="text"
                                className={`form-control ${this.errorClass("title")}`}
                                id="title"
                                name="title"
                                placeholder="Título de video"
                                value={this.state.title.value}
                                onChange={this.handleInput}/>
                            <span className={`${this.errorClass("title")}`}>
                                {this.state.formErrors.title}
                            </span>
                        </div>
                    </div>

                    <div className="col-12 text-left">
                        <div className="form-group mb-4">
                            <label htmlFor="autor">Autor</label>
                            <input type="text"
                                className={`form-control ${this.errorClass("autor")}`}
                                id="autor"
                                name="autor"
                                placeholder="Autor de video"
                                value={this.state.autor.value}
                                onChange={this.handleInput}/>
                            <span className={`${this.errorClass("autor")}`}>
                                {this.state.formErrors.autor}
                            </span>
                        </div>
                    </div>

                    <div className="col-12 text-left">
                        <div className="form-group mb-0">
                            <label htmlFor="gender">Género</label>
                            <Select id="tags"
                                name="tags"
                                placeholder="Géneros"
                                value={this.state.multiValue}
                                options={this.tagsFilter(this.state.tags)}
                                onChange={this.handleMultiChange}
                                isMulti={true}/>
                        </div>
                    </div>
                </div>
            </div>
        ):(null);

        return (
            <div className="row h-100 d-flex align-items-center justify-content-center">
                {this.state.isFile && this.state.isUploading !== true ? showFileForm:(null)}
                {!this.state.isFile && this.state.isUploading !== true ? showDropZone:(null)}
                {this.props.isUploading ? uploading:(null)}
            </div>
        );
    }
}
export default DropVideo;
