import React, { Component } from 'react';

import SvgIcon from '../../svg-icon/SvgIcon';
import './TableEditor.css';

class TableEditor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            children: [props.children] || [],
            isAjax: false,
            details: props.details,
            name: props.listObj.name,
            itemId: props.listObj.itemId
        }

        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleClickButton = this.handleClickButton.bind(this);
    }

    handleTitleChange(event) {
        let name = event.target.value;
        this.setState({name});
        this.props.handleInput(name);

    }

    handleClickButton() {
        this.props.handleButton(this.state.itemId);
    }

    render() {
        return(
            <div className="TableEditor-container col-12 p-0">
                <div className="row m-0 d-flex align-items-end h-100">
                    <div className="col-10 pb-3">
                        <div className="row m-0">
                            <div className="col">
                                <strong>Título</strong>
                            </div>
                            <div className="col-3 col-sm-2 text-right">
                                <span className="TableEditor-details">
                                    {this.state.details}
                                </span>
                            </div>

                            <div className="col-12 pt-2">
                                <div className="form-group mb-0">
                                    <input type="text"
                                        className={`form-control`}
                                        id="title"
                                        name="title"
                                        placeholder="Escribe el título"
                                        value={this.state.name}
                                        onChange={this.handleTitleChange}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-2 align-self-end pb-3">
                        <button type="button"
                            className="btn-blank w-100 blank-bg toggle-content d-flex align-items-center justify-content-center"
                            onClick={this.handleClickButton}>
                            <SvgIcon srcIcon="movielane" width="28" height="28" fill="#7F60EA"/>
                            <span className="pl-1">{this.props.btnTitle}</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default TableEditor;
