import React, { Component } from 'react';
import './Lists.css';

import ConnectionsService from '../../../core/services/ConnectionsService';
import AuthService from '../../../core/services/AuthService';

import Dialog from '../../shared/dialog/Dialog';
import FiltersBox from '../../shared/filters-box/FiltersBox';
import TableHeader from '../../shared/table/table-header/TableHeader';
import TableEditor from '../../shared/table/table-editor/TableEditor';
import TableItemCounter from '../../shared/table/table-item-counter/TableItemCounter';
import TableItem from '../../shared/table/table-item/TableItem';
import SvgIcon from '../../shared/svg-icon/SvgIcon';
import Searcher from '../../shared/searcher/Searcher';

import LoadingBox from '../../shared/loading-box/LoadingBox';
import { ItemFilter, ParamsBuilder, ContainsAll } from '../../../core/helpers';

import { store } from 'react-notifications-component';

import ReactPlayer from 'react-player';
import ReactPaginate from 'react-paginate';
import swal from '@sweetalert/with-react';

import defaultThumbnail from '../../../assets/images/icons/icn_video_default_thumbnail.png';

class Lists extends Component {

    constructor(props) {
        super(props);
        this.functionBinder = this.functionBinder.bind(this);
        this.functionBinder(this);
        this.state = {
            lists: [],
            videos: [],
            videos_ids: [],
            newVideos: [],
            iconFill: "#7F60EA",
            hover: true,
            isCheckedAll: false,
            isEdit: false,
            isAjax: false,
            secundaryView: false,
            listObj: {},
            listObjBase: '',
            listVideosBase: '',
            modalShow: false,
            buttonShow: false,
            isUpdating: false,
            showPlayer: false,
            showPlayerDetails: false,
            playList: [],
            playingUrl: '',
            playingName: '',
            playingIndex: 0,
            itemId:'',
            searchTerm: '',
            searchBy: 'videos',
            perPage: 50,
            currentPage: 0,
            pageCount: 0,
            totalVideos: 0,
            searchTermPL: '',
            perPagePL: 50,
            currentPagePL: 0,
            pageCountPL: 0,
            totalLists: 0,
            perPageVideosList: 50,
            currentPageVideosList: 0,
            pageCountVideosList: 0,
            totalVideosList: 0,
            showFilters: false,
            searchTags: [],
            isVideoAjax: false,
            isSearching: false,
            isCheckedAllList: false,
            checkAllSearch: [],
            checkedVideos: [],
            isSaveDisabled: true
        }

        this.notificationConfig = {
            title: "Atención!",
            message: 'Título, autor y archivo de video son requeridos',
            type: "warning",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 1000,
                onScreen: true
            }
        };
    }

    functionBinder(value) {
        this.getLists = this.getLists.bind(value);
        this.getVideosById = this.getVideosById.bind(value);
        this.deleteChild = this.deleteChild.bind(value);
        this.deleteAll = this.deleteAll.bind(value);
        this.checkAll = this.checkAll.bind(value);
        this.checkAllList = this.checkAllList.bind(value);
        this.newList = this.newList.bind(value);
        this.checkChild = this.checkChild.bind(value);
        this.clickChild = this.clickChild.bind(value);
        this.checkNewChild = this.checkNewChild.bind(value);
        this.editItem = this.editItem.bind(value);
        this.handleAddVideos = this.handleAddVideos.bind(value);
        this.handleSubmitList = this.handleSubmitList.bind(value);
        this.setModalShow = this.setModalShow.bind(value);
        this.editorBtnClicked = this.editorBtnClicked.bind(value);
        this.getAllVideos = this.getAllVideos.bind(value);
        this.handleInputChange = this.handleInputChange.bind(value);
        this.checkIfCanSave = this.checkIfCanSave.bind(value);
        this.handleSignout = this.handleSignout.bind(value);
        this.hidePlayer = this.hidePlayer.bind(value);
        this.changePlayingUrl = this.changePlayingUrl.bind(value);
        this.onVideoEnds = this.onVideoEnds.bind(value);
        this.handleVideoSearch = this.handleVideoSearch.bind(value);
        this.handleListsSearch = this.handleListsSearch.bind(value);
        this.handlePageClick = this.handlePageClick.bind(value);
        this.handlePageClickPL = this.handlePageClickPL.bind(value);
        this.handlePageClickVideosList = this.handlePageClickVideosList.bind(value);
        this.filtersToggle = this.filtersToggle.bind(value);
        this.handleTags = this.handleTags.bind(value);

        this.playVideo = this.playVideo.bind(value);
    }

    playVideo(video) {
        if (video && video.filename && video.title) {
            this.setState({
                showPlayerDetails: true,
                playingUrl: video.filename,
                playingName: video.title
            });
        }
    }

    handleTags(value) {
        let searchTags = value.tags;

        this.setState({isCheckedAllList: false, searchTags, currentPage: 0}, () => {
            this.getAllVideos(this.state.searchTerm);
        });
    }

    handleAddVideos(data) {

        // if(this.state.newVideos.length) {
        //     let items = ItemFilter(this.state.newVideos, 'isChecked', true);
        //     if(items.length) {
        //         let videosCopy = JSON.parse(JSON.stringify(this.state.videos));
        //         items.forEach((item) => {
        //             item.deleted = false;
        //             videosCopy.push(item);
        //         });
        //         this.setState({videos: videosCopy});
        //         this.setModalShow(false);
        //     }
        // }
    }

    prepareAddAll() {

        let objSend = {
            search: [],
            videos: []
        };

        // if(this.state.videos.length) {
        //     let videosPush = ItemFilter(this.state.videos, 'deleted', false);
        //     if(videosPush.length) {
        //         videosPush.forEach((video,index) => {
        //             let obj = {id: video.itemId, orden: index + 1};
        //             if (video['pv_id']) {
        //                 obj['pv_id'] = video['pv_id'];
        //             }
        //             objSend.videos.push(obj);
        //         })
        //     }
        // }

        if (this.state.videos.length && this.state.videos_ids.length) {

            let videosIds = JSON.parse(JSON.stringify(this.state.videos_ids));
            videosIds = videosIds.map((vid) => {
                vid.deleted = false;
                return vid;
            });

            let deletedVideos = this.state.videos.filter(video => video.deleted);
            if (deletedVideos.length) {

                deletedVideos.forEach((deleted) => {
                    let item = videosIds.filter(ids => deleted.id === ids.id);

                    if (item.length) {
                        item.forEach((value) => {
                            value.deleted = true;
                        });

                    }

                });

            }

            let videosPush = videosIds.filter(vid => !vid.deleted);

            if (videosPush.length) {
                videosPush.forEach((video, index) => {
                    let obj = {id: `${video.id}`, orden: index + 1};
                    if (video['pv_id']) {
                        obj['pv_id'] = video['pv_id'];
                    }
                    objSend.videos.push(obj);
                });

            }
        }

        if (this.state.checkedVideos.length) {

            this.state.checkedVideos.forEach((videoID) => {
                let item = this.state.videos.filter(video => video.id === videoID);

                if (!item.length) {
                    let obj = {id: videoID.toString(), pv_id:null, orden: 0};

                    objSend.videos.push(obj);
                }

            });
        }

        if (objSend.videos.length) {
            objSend.videos.forEach((video, index) => {
                video.orden = index + 1;
            });

            let videosDeleted = ItemFilter(this.state.videos, 'deleted', true);

            if (videosDeleted.length) {
                videosDeleted.forEach((deleted) => {
                    let items = objSend.videos.filter(video => videosDeleted.id === video.id);

                    if (items.length) {
                        let index = objSend.videos.indexOf(items[0]);

                        if (index >= 0) {
                            objSend.videos.splice(index, 1);
                        }
                    }
                });

            }
        }

        if(!this.state.isIndividual && this.state.checkAllSearch.length) {
            objSend.search = this.state.checkAllSearch;
        }

        return objSend;
    }

    filtersToggle() {
        this.setState({showFilters: !this.state.showFilters});
    }

    componentDidMount() {
        this.getLists("");
    }

    editorBtnClicked(id) {
        this.setState({newVideos: []});
        this.getAllVideos("");

        this.setModalShow(true);
    }

    setModalShow(value) {
        this.setState({
            isCheckedAllList: false,
            searchTags: [],
            checkAllList: [],
            checkedVideos: [],
            modalShow: value,
            searchTerm: "",
            currentPage: 0
        });
    }

    editItem(event,id) {
        let item = ItemFilter(this.state.lists, 'itemId', id);

        if(item.length) {
            this.setState({listID: item[0].id, secundaryView: true, isEdit: true, listObj: item[0], listObjBase: JSON.stringify(item[0])});
            this.getVideosById(item[0].itemId,null);
        }
    }

    newList() {
        this.setState({videos:[]});
        let list = {
            count_videos: 0,
            id: -1,
            name: "",
            itemId: "-1",
            total_duration: "00:00:00"
        }
        this.setState({secundaryView: true, isEdit: false, listObj: list, listObjBase: JSON.stringify(list)});
    }

    componentDidCatch(error, info) {
        console.log(error, info);
    }

    getLists(text) {
        this.setState({isSearching: true}, () => {

            let searchTerm = text.trim() !== "" ? `&s=${text}`:'';

            ConnectionsService.get(`playlist?page=${this.state.currentPagePL + 1}${searchTerm}`).then((response) => {
                let pageCountPL = 0;
                let totalLists = 0;
                if(response.data && response.data.playlists.length) {
                    response.data.playlists.forEach((playlist) => {
                        playlist.itemId = playlist.id.toString();
                    });

                    if (response.data.total > 0){
                        pageCountPL = Math.ceil(response.data.total / this.state.perPagePL)
                        totalLists = response.data.total;
                    }
                }
                let lists = response.data.playlists.map(obj => obj);
                lists.map(list => list.isChecked = false);
                this.setState({isSearching:false, lists, pageCountPL, totalLists});
            },(error) => {
                if(error.response.data) {
                    if(!error.response.data.auth){
                        this.handleSignout();
                    }
                }
            });

        });
    }

    handleSignout() {
        AuthService.handleSignout();
        window.location.href = '/login';
    }

    handleVideoSearch(text) {
        this.setState({isCheckedAllList:false, searchTerm: text, currentPage: 0}, () => {
            this.getAllVideos(text);
        });

    }

    handleListsSearch(text) {
        this.setState({searchTermPL: text, currentPagePL: 0});
        this.getLists(text);
    }

    handlePageClick = (data) => {
        this.setState({ currentPage: data.selected }, () => {
            this.getAllVideos(this.state.searchTerm);
        });
    }

    handlePageClickPL = (data) => {
        this.setState({ currentPagePL: data.selected }, () => {
            this.getLists(this.state.searchTermPL);
        });
    }

    handlePageClickVideosList = (data) => {
        this.setState({ currentPageVideosList: data.selected }, () => {
            this.getVideosById(this.state.listID,null);
        });
    }

    getAllVideos(text, callBack) {
        this.setState({isVideoAjax: true}, () => {

            let parameters = [
                {'page': this.state.currentPage + 1},
                {'s': text}
            ];

            if (this.state.listID) {
                parameters.push({playlist_id: this.state.listID});
            }

            if (this.state.searchTags.length) {
                parameters.push({'tags[]': this.state.searchTags});
            }

            ConnectionsService.get(`video${ParamsBuilder(parameters)}`).then((response) => {
                let pageCount = 0;
                let totalVideos = 0;

                if(response.data && response.data.videos.length) {
                    response.data.videos.forEach((video) => {
                        video.itemId = video.id.toString();
                    });

                    if (response.data.total > 0){
                        pageCount = Math.ceil(response.data.total / this.state.perPage)
                        totalVideos = response.data.total;
                    }
                }

                let newVideos = response.data.videos.map(obj => obj);
                newVideos.map(video => video.isChecked = this.state.checkedVideos.indexOf(video.id) >= 0 ? true:false);

                let validated = this.validateCheckAll();

                if (this.state.isCheckedAllList || validated.contained){
                    this.setState({newVideos}, () => {
                        this.checkAllList(true, null, null, () => {
                            this.setState({isVideoAjax:false, pageCount, totalVideos}, () => {
                                if (callBack) {
                                    callBack();
                                }
                            });
                        });
                    });
                } else {
                    this.setState({isVideoAjax:false, newVideos, pageCount, totalVideos}, () => {
                        if (callBack) {
                            callBack();
                        }
                    });
                }

            }, error => console.log(error));

        });
    }

    getVideosById(id, total = null, callBack) {
            this.setState({isSearching: true}, () => {

                let urlString = total ? `playlist/${id}`:`playlist/${id}?page=${this.state.currentPageVideosList + 1}`;
            ConnectionsService.get(urlString).then((response) => {
                let pageCountVideosList = 0;
                let totalVideosList = 0;


                if(response.data && response.data.videos.length) {
                    response.data.videos.forEach((video) => {
                        video.itemId = video.id.toString();
                    });

                    if (response.data.video_count > 0) {
                        pageCountVideosList = Math.ceil(response.data.video_count / this.state.perPageVideosList);
                        totalVideosList = response.data.video_count;
                    }
                }
                let videos = response.data.videos.map(obj => obj);
                videos.map(video => video.deleted = false);
                let videos_ids = response.data.videos_ids.map(id => id);

                this.setState({
                    isSearching: false,
                    pageCountVideosList,
                    totalVideosList,
                    videos,
                    videos_ids,
                    listVideosBase: JSON.stringify(videos)},

                    () => {
                        if (callBack) {
                            callBack();
                        }
                    }
                );

            }, error => console.log(error));

        });
    }

    deleteChild(event, id) {

        swal({
            title: "¿Estás seguro?",
            text: "Una vez borrada, no podrás recuperar tu lista",
            icon: "warning",
            buttons: ["No borrar!", "Borrar!"],
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                this.setState({isAjax: true});
                ConnectionsService.delete(`playlist/${id}`)
                    .then((response) => {
                        this.getLists("");
                        this.setState({isAjax: false});
                        store.addNotification({
                            title: "Listo!",
                            message: "Lista eliminada correctamente",
                            type: "success",
                            insert: "bottom",
                            container: "bottom-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 1000,
                                onScreen: true
                            }
                        });
                    }, (e) => {
                        this.setState({isAjax: false});
                        store.addNotification({
                            title: "Error!",
                            message: "Error al intantar eliminar lista",
                            type: "danger",
                            insert: "bottom",
                            container: "bottom-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 1000,
                                onScreen: true
                            }
                        });
                    });
            }
        });
    }

    deleteVideo(index) {
        let videos = this.state.videos;
        this.setState({videos: []});
        videos[index].deleted = !videos[index].deleted;
        this.setState({videos}, () => {
            this.checkIfCanSave();
        });
    }

    checkIfCanSave() {
        var value = true;

        if (this.state.videos.length) {
            this.state.videos.forEach((video) => {
                if(video.deleted) {
                    value = false
                }
            });
        }

        if(JSON.stringify(this.state.listObj) !== this.state.listObjBase) {
            if(this.state.listObj.name) {
                value = false
            }
        }
        this.setState({isSaveDisabled: value});
    }

    deleteAll() {
        if(this.state.lists) {
            let items = ItemFilter(this.state.lists, 'isChecked', true);
            if(items.length) {
                let ids = '';
                items.forEach((item, index) => { ids += index < items.length - 1 ? `${item.id},`:`${item.id}`});
                ConnectionsService.delete(`playlist/?d=${ids}`).then((response) => {
                    this.checkAll(false);
                    this.notificationConfig.type = 'success';
                    this.notificationConfig.message = 'Listas Eliminadas';
                    store.addNotification(this.notificationConfig);
                    this.getLists("");
                }, (error) => {
                    this.notificationConfig.type = 'danger';
                    this.notificationConfig.message = 'Error al intentar eliminar listas';
                });
            }
        }
    }

    checkAll(value) {
        let lists = this.state.lists;
        if(lists.length) {
            lists.map(list => list.isChecked = value);
            this.setState({lists});
            this.setState({isCheckedAll:value});
        }
    }

    validateCheckAll() {

        let contained = false;
        let index = -1;

        if (this.state.checkAllSearch.length) {
            let items = this.state.checkAllSearch.filter(check => check.s === this.state.searchTerm);
            if(items.length) {

                items.forEach((item, i) => {
                    let response = ContainsAll(item.tags, this.state.searchTags) && item.tags.length === this.state.searchTags.length;

                    if (response) {
                        contained = true;
                        index = i;
                    }
                });

            }
        }

        return {contained: contained, index: index};
    }

    checkAllList(value, excluded_id = null, included_id = null, callBack) {

        let checkAllSearch = this.state.checkAllSearch;
        let validated = this.validateCheckAll();
        let index = validated.index;

        if (value) {

            if(!validated.contained) {
                checkAllSearch.push({
                    s: this.state.searchTerm,
                    tags: this.state.searchTags,
                    excluded_ids: excluded_id ? [excluded_id]:[]
                });
                index = checkAllSearch.length - 1;
            } else {

                if (included_id) {
                    let itemInclude = checkAllSearch[index].excluded_ids.indexOf(included_id);
                    if(itemInclude >= 0){
                        checkAllSearch[index].excluded_ids.splice(itemInclude,1);
                    }
                } else if (excluded_id) {
                    checkAllSearch[index].excluded_ids.push(excluded_id);
                }
            }
        } else {

            if (validated.contained) {
                checkAllSearch.splice(index, 1);
                index = -1;
            }
        }

        let newVideos = this.state.newVideos;

        if(newVideos.length && !excluded_id) {

            newVideos.map(video => video.isChecked = value);
        }

        this.setState({checkAllSearch, newVideos, isCheckedAllList:value}, () => {
            if (callBack) {
                callBack();
            }
        });
    }

    checkChild (value) {
        let id = value.target.id.replace("select",'');
        let listsCopy = JSON.parse(JSON.stringify(this.state.lists));
        let item = ItemFilter(listsCopy, 'itemId',id);
        if(item.length) {
            item[0].isChecked = value.target.checked;
            this.setState({lists: listsCopy});
        }
        if(!value.target.checked){
            this.setState({isCheckedAll:false});
        }
    }

    clickChild(value) {
        this.editItem(value,value)
    }

    checkNewChild (value) {

        let id = value.target.id.replace("select",'');
        let newVideos = JSON.parse(JSON.stringify(this.state.newVideos));
        let item = ItemFilter(newVideos, 'itemId',id);
        let checked = value.target.checked;
        if(item.length) {
            item[0].isChecked = checked;
            let checkedVideos = this.state.checkedVideos;

            if (checked) {
                checkedVideos.push(item[0].id);
            } else {

                let checkedIndex = checkedVideos.indexOf(item[0].id);
                checkedVideos.splice(checkedIndex, 1);
            }

            this.setState({newVideos, checkedVideos}, () => {
                if (this.state.isCheckedAllList) {
                    if (!checked) {
                        this.checkAllList(true, item[0].id, null);
                    } else {
                        this.checkAllList(true, null, item[0].id);
                    }
                }
            });
        }
    }

    handleSubmitList () {

        if(JSON.stringify(this.state.listObj) !== this.state.listObjBase || this.state.checkedVideos.length || this.state.checkAllSearch.length || !this.state.isSaveDisabled) {
            if(this.state.listObj.name) {

                this.setState({modalShow: false, isSearching: true}, () => {
                    let objEnvio = this.prepareData();
                    ConnectionsService.addPlaylist(objEnvio,this.state.isEdit).then((response) => {

                        if(response.playListInfo.data && response.playListInfo.data.id) {
                            objEnvio.id = response.playListInfo.data.id;

                            let objSend = this.prepareAddAll();


                            objSend.id = response.playListInfo.data.id;
                            ConnectionsService.addVideos(objSend,this.state.isEdit).then((response) => {
                                this.setState({isVideoAjax: false, isSaveDisabled: true}, () => {

                                    this.getVideosById(this.state.listID, null, () => {
                                        this.getLists("");
                                    });
                                    this.notificationConfig.message = "Lista actualizada";
                                    this.notificationConfig.type = "success";
                                    store.addNotification(this.notificationConfig);

                                });
                            }, (error) => {
                                this.setState({isVideoAjax: false}, () => {
                                    this.getVideosById(this.state.listID, null, () => {
                                        this.getLists("");
                                    });
                                    this.notificationConfig.message = "Error al intentar guardar la lista";
                                    this.notificationConfig.type = "danger";
                                    store.addNotification(this.notificationConfig);
                                });
                            });

                        }

                    }, (error) => {
                        this.setState({isSearching: false}, () => {
                            this.notificationConfig.message = "Error al intentar guardar la lista";
                            this.notificationConfig.type = "danger";
                            store.addNotification(this.notificationConfig);
                        });
                    });
                });



            } else {
                this.notificationConfig.message = "Debes darle un nombre a tu lista";
                store.addNotification(this.notificationConfig);
            }

        }
    }

    showPlayerModal(event, id) {
        this.setState({playingUrl: '', playingName: '', playingIndex: 0});
        this.getVideosById(id, 'total', () => {
            if(this.state.videos.length && this.state.videos[0].filename){
                let title = this.state.videos[0].title !== this.state.videos[0].autor ? `${this.state.videos[0].autor} - ${this.state.videos[0].title}`:this.state.videos[0].autor;
                this.setState({
                    playingName: title,
                    playingUrl: this.state.videos[0].filename,
                    showPlayer: true,
                    playingIndex: 0
                });
            }
        });
    }

    changePlayingUrl(index) {
        if (this.state.videos.length && this.state.videos[index]) {
            let title = this.state.videos[index].title !== this.state.videos[index].autor ? `${this.state.videos[index].autor} - ${this.state.videos[index].title}`:this.state.videos[index].autor;
            this.setState({
                playingUrl: this.state.videos[index].filename,
                playingName: title,
                playingIndex: parseInt(index)
            });
        }
    }

    hidePlayer() {
        this.setState({
            showPlayer: false,
            videos: [],
            listVideosBase: '',
            playingUrl: '',
            playingName: '',
            playingIndex: 0
        });
    }

    prepareData () {
        let playListInfo;

        playListInfo = {
            id: this.state.listObj.itemId !== '-1' ? this.state.listObj.itemId:'',
            name: this.state.listObj.name
        }
        let videos = [];

        if (this.state.videos.length && this.state.videos_ids.length) {

            let deletedVideos = this.state.videos.filter(video => video.deleted);

            let videosIds = JSON.parse(JSON.stringify(this.state.videos_ids));
            videosIds = videosIds.map((vid) => {
                vid.deleted = false;
                return vid;
            });

            if (deletedVideos.length) {

                deletedVideos.forEach((deleted) => {
                    let item = videosIds.filter(ids => deleted.id === ids.id);
                    if (item.length) {
                        item.forEach((value) => {
                            value.deleted = true;
                        });
                    }
                });
            }

            let videosPush = videosIds.filter(vid => !vid.deleted);

            if (videosPush.length) {
                videosPush.forEach((video, index) => {
                    let obj = {id: `${video.id}`, orden: index + 1};
                    if (video['pv_id']) {
                        obj['pv_id'] = video['pv_id'];
                    }
                    videos.push(obj);
                });

            }
        }
        // if(this.state.videos.length) {
        //     let videosPush = ItemFilter(this.state.videos, 'deleted', false);
        //     if(videosPush.length) {
        //         videosPush.forEach((video,index) => {
        //             let obj = {id: video.itemId, orden: index + 1};
        //             if (video['pv_id']) {
        //                 obj['pv_id'] = video['pv_id'];
        //             }
        //             videos.push(obj);
        //         })
        //     }
        // }

        let form = {
            playListInfo,
            videoInfo: {videos:videos}
        }
        return form;
    }

    getListDuration() {
        let time = this.state.listObj.total_duration.split(':');
        let minutes = parseInt(time[2]) > 30 ? parseInt(time[1]) + 1: parseInt(time[1]);
        minutes = minutes.toString().length === 1 ? `0${minutes}`:minutes.toString();
        let hour = `${time[0]} h ${minutes} min`;
        let videosLabel = this.state.listObj.count_videos === 0 ||
            this.state.listObj.count_videos > 1 ? 'videos':'video';
        return `${this.state.listObj.count_videos} ${videosLabel}, ${hour}`;
    }

    handleInputChange (value) {
        this.setState({secundaryView: false});
        let listObj = JSON.parse(JSON.stringify(this.state.listObj));
        listObj.name = value;

        this.setState({listObj, secundaryView: true}, () => {
            this.checkIfCanSave();
        });
    }

    addDefaultSrc(ev){
        ev.target.src = defaultThumbnail;
    }

    onVideoEnds() {
        let index = `${this.state.playingIndex + 1}`;

        this.changePlayingUrl(index);
    }

    render() {
        const secundaryHeader = this.state.secundaryView ? (
            <TableEditor btnTitle="Agregar video"
                details={this.getListDuration()}
                listObj={this.state.listObj}
                handleButton={this.editorBtnClicked}
                handleInput={this.handleInputChange}/>
        ):(null);

        const listLists = this.state.lists.length ? this.state.lists.map(video =>
            <TableItem srcIcon="playlist"
                autor={video.count_videos ? `${video.count_videos} videos`:''}
                itemId={video.itemId}
                title={video.name}
                key={video.itemId}
                isChecked={video.isChecked}
                handleCheck={this.checkChild}
                handleItemClick={this.clickChild}>
                {
                    video.count_videos > 0 && (<button className="table-btn"
                        id={`add-${video.itemId}`}
                        onClick={(e) => {this.showPlayerModal(e,video.itemId)}}
                        disabled={this.state.isAjax}>
                        <SvgIcon srcIcon="play" width="35" height="35" fill={this.state.iconFill}/>
                        <span className="table-btn-span">Reproducir</span>
                    </button>)
                }
                <button className="table-btn"
                    id={`add-${video.itemId}`}
                    onClick={(e) => {this.editItem(e,video.itemId)}}
                    disabled={this.state.isAjax}>
                    <SvgIcon srcIcon="edit" width="35" height="35" fill={this.state.iconFill}/>
                    <span className="table-btn-span">Editar</span>
                </button>
                <button className="table-btn"
                    id={`delete-${video.itemId}`}
                    onClick={(e) => {this.deleteChild(e,video.itemId)}}
                    disabled={this.state.isAjax}>
                    <SvgIcon srcIcon="close" width="35" height="35" fill={this.state.iconFill}/>
                    <span className="table-btn-span">Eliminarss</span>
                </button>
            </TableItem>
        ):(null);

        const listVideosPlaceholder = !this.state.videos.length && this.state.secundaryView ? (
            <div className="w-100 h-100 d-flex align-items-center justify-content-center pt-5">
                <h3>
                    Esta lista aún no tiene videos asignados
                </h3>
            </div>
        ):(null);

        const listVideos = this.state.videos.length ? this.state.videos.map((video, index) => {
            let vars = {
                autor: video.deleted ? `${video.autor} `:video.autor,
                itemId: video.itemId,
                title: video.title,
                key: index + 1,
                position: video.orden,
                srcIcon: "movielane",
                deleted: video.deleted,
                tags: video.tags
            }
            return (
                <TableItemCounter {...vars}>
                    <button className="table-btn" id={`add-${video.itemId}`}
                        onClick={() => {this.playVideo(video)}}>
                        <SvgIcon srcIcon="play" width="35" height="35" fill="#7F60EA"/>
                        <span className="table-btn-span">Reproducir</span>
                    </button>
                    <button className="table-btn"
                        id={`delete-${vars.itemId}`}
                        onClick={() => {this.deleteVideo(`${index}`)}}
                        disabled={this.state.isAjax}>
                        <SvgIcon srcIcon="close" width="35" height="35" fill={this.state.iconFill}/>
                        <span className="table-btn-span">Eliminar</span>
                    </button>
                </TableItemCounter>
            );
        }):(null);

        const listNewVideos = this.state.newVideos.length ? this.state.newVideos.map((video,index) => {
            return (
                <TableItem srcIcon="movielane"
                    tags={video.tags}
                    autor={video.autor}
                    itemId={video.itemId}
                    title={video.title}
                    key={video.itemId}
                    isChecked={video.isChecked}
                    handleCheck={this.checkNewChild}/>
            );
        }):(null);

        const showList = this.state.videos.length ? this.state.videos.map((list, index) => {
            let title = list.title !== list.autor ? `${list.autor} - ${list.title}`:list.autor;
            return (
                <div className={`row m-0 mouse-pointer p-2 ${list.filename === this.state.playingUrl ? 'on-playing-row':''}`} key={index} onClick={(e) => {this.changePlayingUrl(index)}}>
                    <div className="col-12 p-0">
                        <div className='row m-0 pb-2'>
                            <div className="col max-width-50px p-2">

                                <img src={defaultThumbnail} alt="" width="50"/>
                            </div>
                            <div className="col pr-0">
                                <span className="font-size-12 playing-title">{title}</span>
                                { list.filename === this.state.playingUrl && (<span className="font-size-10 font-color-fff"><br/>Reproduciendo</span>)}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }):(null);

        return (
            <div className="Lists-container">
                <Dialog show={this.state.modalShow}
                    onHide={() => this.setModalShow(false)}
                    buttonTitle="Agregar video"
                    buttonShow={true}
                    isUploading={false}
                    buttonCallBack={this.handleSubmitList}>
                    <h4 className="text-center w-100"><strong>Agregar video</strong></h4>
                    <h6 className="text-center w-100">Selecciona uno o varios videos</h6>
                    <div className="p-3">
                        <div className='row'>
                            {/*<div className="col-1 p-0">
                                <button className="styless-btn w-100" onClick={this.filtersToggle}> <SvgIcon srcIcon="filter" width="35" height="35" fill="#7F60EA"/> Filtrar</button>
                            </div>*/}
                            <div className='col'>
                                <Searcher updateInput={this.handleVideoSearch} placeholder="Buscar video"/>
                            </div>
                            <div className="col-12">
                                <FiltersBox active={true}
                                    showCloseBtn={false}
                                    childrenData={this.handleTags}
                                    onHide={() => this.setState({showFilters: false})}/>
                            </div>
                        </div>
                    </div>
                    <div className="px-3">
                        <TableHeader updateCheck={this.checkAllList} isCheckedAll={this.state.isCheckedAllList}/>
                    </div>

                    <div className="px-3 modal-video-box">
                        {this.state.newVideos.length && !this.state.isVideoAjax ? listNewVideos:(null)}
                        { this.state.isVideoAjax ? <LoadingBox animationType="loading"/>:(null) }
                    </div>
                    <div className="px-3">
                        {this.state.totalVideos > this.state.perPage ? (
                            <ReactPaginate previousLabel={'Anterior'}
                                nextLabel={'Siguiente'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}/>
                        ):(null)}
                    </div>
                </Dialog>

                <Dialog show={this.state.showPlayerDetails}
                    onHide={() => this.setState({showPlayerDetails: false})}
                    buttonTitle="Subir video"
                    buttonShow={false}
                    isUploading={false}>
                    <h4 className="text-center">{this.state.playingName}</h4>
                    <ReactPlayer controls={true} url={this.state.playingUrl} playing={this.state.showPlayerDetails} width='100%' height='calc(100% - 50px)' />
                </Dialog>

                <Dialog show={this.state.showPlayer}
                    onHide={() => this.hidePlayer()}
                    buttonTitle="Subir video"
                    buttonShow={false}
                    isUploading={false}>


                    <div className="row m-0">
                        <div className="col-12 col-sm-7">
                            <h4 className="text-center">{this.state.playingName}</h4>
                            <div className="row m-0 pt-4">
                                <div className="col-12 p-0 text-center">
                                    <ReactPlayer controls={true}
                                        url={this.state.playingUrl}
                                        playing={this.state.showPlayer}
                                        className="video-player-setts"
                                        onEnded={() => {this.onVideoEnds()}}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-5 pt-3">
                            <h5>Lista</h5>

                            <div className="row m-0 list-container">
                                <div className="col-12 p-0">
                                    {showList}
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <div className="row m-0 pb-4">
                    {
                        this.state.secundaryView ? (
                            <div className="col-1 p-0 w-100 d-flex align-items-center max-width-35px">
                                <button className="btn btn-default p-0" onClick={() => {this.setState({secundaryView: false})}}>
                                    <SvgIcon srcIcon="left" width="35px"/>
                                </button>
                            </div>
                        ):(null)
                    }
                    <div className={`${this.state.secundaryView ? "col-10 pl-2 pr-0":"col-12 p-0"} col-md w-100 d-flex align-items-center`}>
                        <h4 className="mb-0">
                            Mis listas
                        </h4>
                    </div>
                    {
                        !this.state.isEdit && !this.state.secundaryView ? <div className="col-12 col-md-3 pb-2 pb-md-0 pt-2 pt-md-0">
                            <Searcher updateInput={this.handleListsSearch} placeholder="Buscar lista"/>
                        </div>:(null)
                    }
                    <div className="col-12 pr-md-0 col-md-3 col-lg-2">
                        {
                            !this.state.secundaryView ? (
                                <button type="button"
                                    className="btn-blank w-100 blank-bg toggle-content d-flex align-items-center justify-content-center"
                                    onClick={this.newList}>
                                    <SvgIcon srcIcon="playlist" width="28" height="28" fill="#7F60EA"/>
                                    <span className="pl-1">Nueva lista</span>
                                </button>
                            ):(
                                <button type="button" className="btn-standard w-100 success-bg toggle-content" onClick={this.handleSubmitList} disabled={this.state.isSaveDisabled}>
                                    <span>Guardar</span>
                                </button>
                            )
                        }
                    </div>
                </div>

                <div className="row m-0">
                    {
                        !this.state.secundaryView ? (
                            <TableHeader updateCheck={this.checkAll} isCheckedAll={this.state.isCheckedAll}>
                                <button className="table-btn" id="delete-all" onClick={this.deleteAll}>
                                    <SvgIcon srcIcon="close" width="35" height="35" fill={this.state.iconFill}/>
                                    <span className="table-btn-span">Eliminar</span>
                                </button>
                            </TableHeader>
                        ):(secundaryHeader)
                    }
                </div>

                <div className="row m-0 Lists-list-container">
                    {
                        !this.state.isSearching && this.state.lists.length && !this.state.secundaryView ? listLists:(null)
                    }

                    {
                        !this.state.isSearching && this.state.videos.length && this.state.secundaryView ? listVideos:(null)

                    }

                    {
                        !this.state.isSearching && this.state.secundaryView && !this.state.videos.length ? listVideosPlaceholder:(null)
                    }

                    {
                        this.state.isSearching ? (
                            <LoadingBox animationType="loading"/>
                        ):(null)
                    }
                </div>


                {this.state.totalLists > this.state.perPagePL && !this.state.secundaryView ? (
                    <ReactPaginate previousLabel={'Anterior'}
                        nextLabel={'Siguiente'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={this.state.pageCountPL}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClickPL}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}/>
                ):(null)}

                {this.state.totalVideosList > this.state.perPageVideosList && this.state.secundaryView ? (
                    <ReactPaginate previousLabel={'Anterior'}
                        nextLabel={'Siguiente'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={this.state.pageCountVideosList}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClickVideosList}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}/>
                ):(null)}
            </div>
        );
    }
}
export default Lists;
