import React from 'react';
import './Alert.css';

function Alert(props) {
    if(props.showAlert === 'true') {
        return (
            <div className={`col-12 alert-box alert-${props.type}`}>
                <span>
                    {props.msg}
                </span>
            </div>
        );
    } else {
        return (<span></span>);
    }
}

export default Alert;
