import React, { Component } from 'react';

import SvgIcon from '../../svg-icon/SvgIcon';

import './TableItem.css';

class TableItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            children: [props.children] || [],
            isChecked: this.props.isChecked,
            tags: this.props.tags || []
        }
        this.handleCheck = this.handleCheck.bind(this);
        this.handleItemClick = this.handleItemClick.bind(this);
        this.valuesItem = this.props.itemId;
    }

    handleCheck (event) {
        this.props.handleCheck(event);
    }

    handleItemClick(item) {
        if (this.props.handleItemClick) {
            this.props.handleItemClick(item);
        }
    }

    render() {
        const listTags = this.state.tags.length ? this.state.tags.map((tag,index) => {
            return (<span className="TableItem-chip" key={index}>{tag.name}</span>)
        }):(null);

        const listChildren =  React.Children.map( this.state.children, (child) => {
            if (child) {
                return (
                    <div className="col-1 pl-0 d-flex align-items-center max-width-70px">
                        {child}
                    </div>
                );
            }
            return (null);
        });

        return(
            <div className="TableItem-container col-12 p-0">
                <div className="row m-0 d-flex align-items-center h-100">
                    <div className="col-1 d-flex align-items-center max-width-50px">
                        <label className="custom-checkbox-container">&nbsp;
                            <input type="checkbox"
                                id={`select${this.props.itemId}`}
                                onChange={this.handleCheck}
                                checked={this.props.isChecked}/>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="col pl-0 mb-0 unselect-text d-flex align-items-center" onClick={(e) => { this.handleItemClick(this.props.itemId)}}>
                        <SvgIcon srcIcon={this.props.srcIcon} width="40" fill="#7F60EA"/>
                        <div className="TableItem-title-container">
                            <div>
                                <strong>
                                    {this.props.title}
                                </strong>
                            </div>
                            <div>
                                <span>
                                    {this.props.autor}
                                </span>
                            </div>
                            <div>
                                {listTags}
                            </div>
                        </div>
                    </div>
                    {
                        this.state.children.length ? listChildren:(null)
                    }
                </div>
            </div>
        );
    }
}

export default TableItem;
