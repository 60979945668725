import React, { Component } from 'react';

import SvgIcon from '../../svg-icon/SvgIcon';

import './TableItemCounter.css';

class TableItemCounter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            children: [props.children] || [],
            position: props.position,
            srcIcon: props.srcIcon,
            deleted: props.deleted,
            tags: this.props.tags || []
        }
    }

    render() {
        const listTags = this.state.tags.length ? this.state.tags.map((tag,index) => {
            return (<span className="TableItemCounter-chip" key={index}>{tag.name}</span>)
        }):(null);
        const listChildren = React.Children.map(this.state.children, child => (
            <div className="col-1 pl-0 d-flex align-items-center max-width-70px">
                {child}
            </div>
        ))
        let iconColor = this.props.deleted ? "#969696":"#7F60EA";
        let backgroundColor = {background: this.props.deleted ? "#dadada":"#F6F7F8"};
        let textColor = {color: this.props.deleted ? "#c5c5c5":"#494950"};
        return(
            <div className="TableItemCounter-container col-12 p-0" style={backgroundColor}>
                <div className="row m-0 d-flex align-items-center h-100">
                    <div className="col-1 d-flex align-items-center max-width-50px">
                        <strong style={textColor}>{this.state.position}</strong>
                    </div>
                    <div className="col pl-0 mb-0 unselect-text">
                        <div className='row m-0'>
                            <div className="col p-0 max-width-35px">
                                <SvgIcon srcIcon={this.state.srcIcon} width="35" fill={iconColor}/>
                            </div>
                            <div className="col pl-1">
                                <div className="TableItemCounter-title-container pl-1">
                                    <div>
                                        <strong style={textColor}>
                                            {this.props.title}
                                        </strong>
                                    </div>
                                    <div>
                                        <span style={textColor}>
                                            {this.props.autor}
                                        </span>
                                    </div>
                                    <div>
                                        {listTags}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.children.length ? listChildren:(null)
                    }
                </div>
            </div>
        );
    }
}

export default TableItemCounter;
