import React, { Component } from 'react';
import Lottie from 'react-lottie';

import {
    Loading,
    Searching
} from '../../../assets/animations/';

class LottieBox extends Component{

    constructor(props) {
        super(props);
        this.state = {
            isStopped: false,
            isPaused: false,
            defaultOptions: {
                loop: true,
                autoplay: true,
                animationData: '',
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                }
            }
        };

        this.configureOptions = this.configureOptions.bind(this);
    }

    componentDidMount() {
        this.configureOptions();
    }

    configureOptions() {
        let defaultOptions = this.state.defaultOptions;

        switch(this.props.animation) {
            case 'loading':
                defaultOptions.animationData = Loading;
                break;
            case 'searching':
                defaultOptions.animationData = Searching;
                break;
            default:
                break;
        }

        this.setState({defaultOptions});
    }

    render() {

        return (
            <div>
                <Lottie options={this.state.defaultOptions}
                    height={this.props.height}
                    width={this.props.width}
                    isStopped={this.state.isStopped}
                    isPaused={this.state.isPaused}/>
            </div>
        )
    }
}

export default LottieBox;
