import React, { Component } from 'react';

import './TableHeader.css';

class TableHeader extends Component {

    constructor(props) {
        super(props);
        this.checkAll = this.checkAll.bind(this);
        this.state = {
            children: [props.children] || []
        }
    }

    checkAll () {
        let element = document.getElementById("selectAll");
        this.props.updateCheck(element.checked);
    }

    render() {

        const listChildren = React.Children.map(this.state.children, (child) => {

            if (child) {
                return (
                        <div className="col-1 pl-0 d-flex align-items-center max-width-70px">
                            {child}
                        </div>
                    )
            }
            return (null);
        })

        return(
            <div className="TableHeader-container col-12 p-0">
                <div className="row m-0 d-flex align-items-center h-100">
                    <div className="col-1 d-flex align-items-center max-width-50px">
                        <label className="custom-checkbox-container">&nbsp;
                            <input type="checkbox"
                                id="selectAll"
                                onChange={this.checkAll}
                                checked={this.props.isCheckedAll}/>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="col pl-0">
                        <label htmlFor="selectAll" className="mb-0 unselect-text">
                            <strong>
                                Seleccionar todo
                            </strong>
                        </label>
                    </div>
                    {
                        this.state.children.length ? listChildren:(null)
                    }
                </div>
            </div>
        );
    }
}

export default TableHeader;
