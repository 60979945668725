import React, { Component } from 'react';
import './Searcher.css';

import SvgIcon from '../svg-icon/SvgIcon';

class Searcher extends Component {

    constructor(props) {
        super();
        this.state = {
            placeholder: props.placeholder || 'Buscar...',
            type: props.type || 'text',
            className: `Searcher-input ${props.className || ''}`,
            showSearchIcon: true,
            searchField: ''
        }
        this.searchField = "";
        this.handleChange = this.handleChange.bind(this);
        this.handleLabel = this.handleLabel.bind(this);
        this.updateParent = this.updateParent.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.timer = null;
    }

    componentDidMount() {
        const typer = document.getElementById('searcher');
        typer.addEventListener('keypress', this.handleKeyPress);
        typer.addEventListener('keyup', this.handleKeyUp);
    }

    handleKeyPress(e) {
        window.clearTimeout(this.timer);
    }

    handleKeyUp(e) {
        window.clearTimeout(this.timer);
        this.timer = window.setTimeout(() => {
            this.updateParent(this.searchField);
        }, 1000);
    }

    handleChange (event) {
        this.setState({searchField: event.target.value});
        let value = event.target.value.trim();
        this.setState({showSearchIcon: true});
        this.searchField = value;
        if(value) {
            this.setState({showSearchIcon: false});
        }
    }

    handleLabel() {
        let value = this.state.searchField;
        if(this.state.searchField !== '') {
            this.setState({searchField: '', showSearchIcon: true});
            value = '';
        }
        this.updateParent(value);
    }

    updateParent(value) {
        this.props.updateInput(value);
    }

    render() {
        return (
            <div className="Searcher-container">
                <input id="searcher"
                    type={this.state.type}
                    placeholder={this.state.placeholder}
                    className={this.state.className}
                    onChange={(event) => {this.handleChange(event)}}
                    value={this.state.searchField}/>
                <label htmlFor="searcher"
                    className="Searcher-label d-flex align-items-center justify-content-center"
                    onClick={this.handleLabel}>
                    <SvgIcon srcIcon={this.state.showSearchIcon ? 'search':'close'} width="30" height="30"/>
                </label>
            </div>
        );
    }
}
export default Searcher;
