import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import './Videos.css';

import ConnectionsService from '../../../core/services/ConnectionsService';
import AuthService from '../../../core/services/AuthService';

import TableHeader from '../../shared/table/table-header/TableHeader';
import TableItem from '../../shared/table/table-item/TableItem';
import SvgIcon from '../../shared/svg-icon/SvgIcon';
import Searcher from '../../shared/searcher/Searcher';
import { ItemFilter, ParamsBuilder, ContainsAll } from '../../../core/helpers';

import Dialog from '../../shared/dialog/Dialog';
import FiltersBox from '../../shared/filters-box/FiltersBox';
import DropVideo from '../../shared/drop-video/DropVideo';
import VideoForm from '../../shared/video-form/VideoForm';

import LoadingBox from '../../shared/loading-box/LoadingBox';

import { store } from 'react-notifications-component';

import ReactPlayer from 'react-player';

import ReactPaginate from 'react-paginate';
import swal from '@sweetalert/with-react';

class Videos extends Component {

    constructor(props) {
        super();
        this.functionBinder = this.functionBinder.bind(this);
        this.functionBinder(this);
        this.state = {
            videos: [],
            newList: [],
            isCheckedAll: false,
            ajax: false,
            modalShowDrop: false,
            buttonShow: false,
            modalShowDropDrop: false,
            modalShowEdit: false,
            buttonShowDrop: false,
            buttonShowEdit: false,
            videoInfo: {
                title:'',
                autor:'',
                file: [],
                tags: []
            },
            videoEditing: {},
            isIndividual:false,
            videosPicked: [],
            listPicked: '',
            isUploading: false,
            playlistVideos: [],
            showPlayer: false,
            playingUrl: '',
            playingName: '',
            tagList: [],
            perPage: 50,
            currentPage: 0,
            pageCount: 0,
            totalVideos: 0,
            perPagePL: 50,
            currentPagePL: 0,
            pageCountPL: 0,
            totalPL: 0,
            searchTerm: '',
            searchBy: 'videos',
            showVideoInfo: false,
            shouldRedirect: false,
            redirectTo: '',
            showFilters: false,
            searchTags: [],
            searchTimer: null,
            isAjax: false,
            isListAjax: false,
            checkedVideos: [],
            checkAllSearch: []
        };

        this.notificationConfig = {
            title: "Atención!",
            message: '',
            type: "warning",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 1000,
                onScreen: true
            }
        };
    }

    functionBinder(value) {
        this.getVideos = this.getVideos.bind(value);
        this.getListById = this.getListById.bind(value);
        this.getTagList = this.getTagList.bind(value);
        this.deleteChild = this.deleteChild.bind(value);
        this.deleteAll = this.deleteAll.bind(value);
        this.addAll = this.addAll.bind(value);
        this.handleAddAll = this.handleAddAll.bind(value);
        this.checkAll = this.checkAll.bind(value);
        this.playVideo = this.playVideo.bind(value);
        this.checkSingle = this.checkSingle.bind(value);
        this.checkChild = this.checkChild.bind(value);
        this.handleSubmit = this.handleSubmit.bind(value);
        this.handleSubmitEdit = this.handleSubmitEdit.bind(value);
        this.handleSubmitVideo = this.handleSubmitVideo.bind(value);
        this.setModalShowDrop = this.setModalShowDrop.bind(value);
        this.setModalShowEdit = this.setModalShowEdit.bind(value);
        this.handleEditVideo = this.handleEditVideo.bind(value);
        this.setModalShow = this.setModalShow.bind(value);
        this.handleChildren = this.handleChildren.bind(value);
        this.handleVideoForm = this.handleVideoForm.bind(value);
        this.getAllList = this.getAllList.bind(value);
        this.handleRadio = this.handleRadio.bind(value);
        this.closeModal = this.closeModal.bind(value);
        this.prepareData = this.prepareData.bind(value);
        this.handleSignout = this.handleSignout.bind(value);
        this.handlePageClick = this.handlePageClick.bind(value);
        this.handleVideoSearch = this.handleVideoSearch.bind(value);
        this.setNotification = this.setNotification.bind(value);
        this.handleSelect = this.handleSelect.bind(value);
        this.handleVideoDetails = this.handleVideoDetails.bind(value);
        this.filtersToggle = this.filtersToggle.bind(value);
        this.handleTags = this.handleTags.bind(value);
    }

    componentDidMount() {
        this.getVideos("");
    }

    closeModal() {
        this.setState({isIndividual:false,videosPicked: [],listPicked:''});
        this.setModalShow(false);
    }

    filtersToggle() {
        this.setState({showFilters: !this.state.showFilters});
    }

    handleVideoDetails(video) {
        this.setState({videoDetails: video}, () => {
            this.setState({showVideoInfo: true});
        });
    }

    handleSelect(e) {
        this.setState({searchBy: e.target.value}, () => {
            if (this.state.searchTerm !== "") {
                this.getVideos(this.state.searchTerm);
            }
        });
    }

    getListById(id) {
        ConnectionsService.get(`playlist/${id}`).then((response) => {
            let playlistVideos = [];
            if(response.data && response.data.videos.length) {
                playlistVideos = response.data.videos.map((video) => {return {id: video.id, pv_id: video.pv_id}});
            }
            this.setState({playlistVideos});
        }, error => console.log(error));
    }

    getTagList(callBack) {
        ConnectionsService.get('tag').then((response) => {
            if (response.data && response.data.length) {
                this.setState({tags: response.data}, () => {
                    callBack();
                });
            }
        });
    }

    handleVideoSearch(text) {
        this.setState({isCheckedAll:false, searchTerm: text, currentPage: 0}, () => {
            this.getVideos(text);
        });

    }

    getVideos(text, callBack) {

        this.setState({isAjax:true, videos: []}, () => {

            let parameters = [{'page':this.state.currentPage + 1},{'s':text}];

            if (this.state.searchTags.length) {
                parameters.push({'tags[]':this.state.searchTags});
            }
            let videos = [];

            ConnectionsService.get(`video${ParamsBuilder(parameters)}`).then((response) => {
                let pageCount = 0;
                let totalVideos = 0;
                if(response.data && response.data.videos.length) {
                    response.data.videos.forEach((video) => {
                        video.itemId = video.id.toString();
                    });

                    if (response.data.total > 0){
                        pageCount = Math.ceil(response.data.total / this.state.perPage)
                        totalVideos = response.data.total;
                    }

                }

                videos = response.data.videos.map(obj => obj);
                videos.map(video => video.isChecked = this.state.checkedVideos.indexOf(video.id) >= 0 ? true:false);

                let validated = this.validateCheckAll();

                if (this.state.isCheckedAll || validated.contained){
                    this.setState({videos}, () => {
                        this.checkAll(true, null, null, () => {
                            this.setState({isAjax:false, pageCount, totalVideos}, () => {
                                if (callBack) {
                                    callBack();
                                }
                            });
                        });
                    });
                } else {
                    this.setState({isAjax:false, videos, pageCount, totalVideos}, () => {
                        if (callBack) {
                            callBack();
                        }
                    });
                }

            }, (error) => {
                if(error.response.data) {
                    if(!error.response.data.auth){
                        this.handleSignout();
                    }
                }
            });

        });

    }

    handleSignout() {
        AuthService.handleSignout();
        window.location.href = '/login';
    }

    handleRadio(event) {
        this.getListById(event.target.id);
        this.setState({listPicked:event.target.id});
    }

    handleAddAll() {
        console.log("hola");
        let objSend = this.prepareAddAll();
        if (objSend.search.length || objSend.videos.length) {
            let isCustomer = this.checkPermisionByRole("customer") ? 'customer/':'';
            ConnectionsService.post(`${isCustomer}playlist/${this.state.listPicked}/videos`,objSend).then((response) => {
                this.setState({listPicked:'', videosPicked: [], isIndividual: false,playlistVideos: []});
                this.checkAll(false);
                this.notificationConfig.type = 'success';
                this.notificationConfig.message = 'Videos actualizados';
                store.addNotification(this.notificationConfig);
                this.setModalShow(false);
            },(error) => {
                this.notificationConfig.type = 'danger';
                this.notificationConfig.message = 'Error al intentar actualizar videos';
            });
        }
    }

    prepareAddAll() {

        let objSend = {
            search: [],
            videos: []
        };

        if (this.state.checkedVideos.length) {
            let videosIds = [];

            this.state.checkedVideos.forEach((videoID, index) => {
                videosIds.push({id: videoID, orden: index + 1});
            });
            objSend.videos = videosIds;
        }

        if(!this.state.isIndividual && this.state.checkAllSearch.length) {
            objSend.search = this.state.checkAllSearch;
        }
        return objSend;
    }

    getAllList(text, callBack) {
        this.setState({isListAjax: true, newList: []}, () => {

            let searchTerm = text.trim() !== "" ? `?s=${text}`:'';

            // if (this.state.isIndividual && this.state.videosPicked.length === 1) {
            //     searchTerm += searchTerm.length ? `&video_id=${this.state.videosPicked[0].id}`: `?video_id=${this.state.videosPicked[0].id}`;
            // }

            if(this.state.isIndividual || this.state.checkedVideos.length === 1) {
                searchTerm += searchTerm.length ? `&video_id=${this.state.videosPicked[0].id}`: `?video_id=${this.state.videosPicked[0].id}`;
            }

            let isCustomer = this.checkPermisionByRole("customer") ? 'customer/':'';

            ConnectionsService.get(`${isCustomer}playlist${searchTerm}`).then((response) => {
                if(response.data && response.data.length) {
                    response.data.forEach((list) => {
                        list.itemId = list.id.toString();
                    });
                }

                let newList = response.data.playlists.map(obj => obj);
                newList.map(list => list.isChecked = false);
                this.setState({isListAjax: false, newList}, () => {

                    if (callBack) {
                        callBack();
                    }
                });
            }, error => console.log(error));

        });
    }

    checkPermisionByRole(role) {
        let token = JSON.parse(localStorage.getItem('token'));

        if (token && token.role === role) {
            return true
        }

        return false
    }

    checkIfCanUpload () {
        if (this.checkPermisionByRole("admin")) {
            return(
                <div className="col-12 pr-0 col-md-3 col-lg-2">
                    <button type="button" className="btn-standard w-100 primary-bg toggle-content" onClick={() => {this.setModalShowDrop(true)}}>
                        <span>Subir video</span>
                    </button>
                </div>
            );
        }

        return null
    }

    deleteChild(event, id) {
        swal({
            title: "¿Estás seguro?",
            text: "Una vez borrado, no podrás recuperar tu video",
            icon: "warning",
            buttons: ["No borrar!", "Borrar!"],
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                this.setState({ajax: true});

                ConnectionsService.delete(`video/${id}`)
                    .then((response) => {
                        this.getVideos("");
                        this.setState({ajax: false});
                        store.addNotification({
                            title: "Listo!",
                            message: "Video eliminado correctamente",
                            type: "success",
                            insert: "bottom",
                            container: "bottom-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 1000,
                                onScreen: true
                            }
                        });
                    }, (e) => {
                        this.setState({ajax: false});
                        store.addNotification({
                            title: "Error!",
                            message: "Error al intantar eliminar video",
                            type: "danger",
                            insert: "bottom",
                            container: "bottom-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 1000,
                                onScreen: true
                            }
                        });
                    });
            }
        });
    }

    playVideo(video) {
        if (video && video.filename && video.title) {
            this.setState({
                showPlayer: true,
                playingUrl: video.filename,
                playingName: video.title
            });
        }
    }

    checkSingle(data) {
        this.setState({isIndividual: true, videosPicked: [{id: data, pv_id:null}]}, () => {
            this.getAllList("", () => {
                this.setModalShow(true);
            })
        });
    }

    deleteAll() {
        if(this.state.videos) {
            let items = ItemFilter(this.state.videos, 'isChecked', true);
            if(items.length) {
                let ids = '';
                items.forEach((item, index) => { ids += index < items.length - 1 ? `${item.id},`:`${item.id}`});
                ConnectionsService.delete(`video/?d=${ids}`).then((response) => {
                    this.checkAll(false);
                    this.notificationConfig.type = 'success';
                    this.notificationConfig.message = 'Videos Eliminados';
                    store.addNotification(this.notificationConfig);
                    this.getVideos("");
                }, (error) => {
                    this.notificationConfig.type = 'danger';
                    this.notificationConfig.message = 'Error al intentar eliminar videos';
                });
            }
        }
    }

    addAll() {
        if(this.state.isCheckedAll && this.state.checkAllSearch.length) {

            this.setState({isIndividual: false}, () => {
                this.getAllList("", () => {
                    this.setModalShow(true);
                });
            });



        } else if(this.state.videos.length) {
            let items = ItemFilter(this.state.videos, 'isChecked', true);
            if(items.length) {
                let ids = items.map((item) => { return {id: item.id, pv_id:null}});

                this.setState({videosPicked:ids});
                this.getAllList("");
                this.setModalShow(true);
            }
        }
    }

    validateCheckAll() {

        let contained = false;
        let index = -1;

        if (this.state.checkAllSearch.length) {
            let items = this.state.checkAllSearch.filter(check => check.s === this.state.searchTerm);
            if(items.length) {

                items.forEach((item, i) => {
                    let response = ContainsAll(item.tags, this.state.searchTags) && item.tags.length === this.state.searchTags.length;

                    if (response) {
                        contained = true;
                        index = i;
                    }
                });

            }
        }

        return {contained: contained, index: index};
    }

    checkAll(value, excluded_id = null, included_id = null, callBack) {

        let checkAllSearch = this.state.checkAllSearch;
        let validated = this.validateCheckAll();
        let index = validated.index;

        if (value) {

            if(!validated.contained) {
                checkAllSearch.push({
                    s: this.state.searchTerm,
                    tags: this.state.searchTags,
                    excluded_ids: excluded_id ? [excluded_id]:[]
                });
                index = checkAllSearch.length - 1;
            } else {

                if (included_id) {
                    let itemInclude = checkAllSearch[index].excluded_ids.indexOf(included_id);
                    if(itemInclude >= 0){
                        checkAllSearch[index].excluded_ids.splice(itemInclude,1);
                    }
                } else if (excluded_id) {
                    checkAllSearch[index].excluded_ids.push(excluded_id);
                }
            }
        } else {
            this.setState({checkedVideos: []});
            if (validated.contained) {
                checkAllSearch.splice(index, 1);
                index = -1;
            }
        }

        let videos = this.state.videos;

        if(videos.length && !excluded_id) {

            videos.map(video => video.isChecked = value);
        }
        console.log("array0:",JSON.parse(JSON.stringify(checkAllSearch)));
        this.setState({checkAllSearch, videos, isCheckedAll:value}, () => {
            if (callBack) {
                callBack();
            }
        });
    }

    checkChild (value) {

        let id = value.target.id.replace("select",'');
        let videosCopy = JSON.parse(JSON.stringify(this.state.videos));
        let item = ItemFilter(videosCopy, 'itemId',id);
        let checked = value.target.checked;
        if(item.length) {
            item[0].isChecked = checked;
            let checkedVideos = this.state.checkedVideos;

            if (checked) {
                checkedVideos.push(item[0].id);
            } else {
                let checkedIndex = checkedVideos.indexOf(item[0].id);
                checkedVideos.splice(checkedIndex, 1);
            }

            this.setState({videos: videosCopy, checkedVideos}, () => {
                if (this.state.isCheckedAll) {
                    if (!checked) {
                        this.checkAll(true, item[0].id, null);
                    } else {
                        this.checkAll(true, null, item[0].id);
                    }
                }
            });
        }

    }

    handleSubmitEdit(data) {

        let notificationConfig = {
            title: "Atención!",
            message: 'Título y autor archivo de video son requeridos',
            type: "warning",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 1000,
                onScreen: true
            }
        };

        if(this.state.videoEditing.title && this.state.videoEditing.autor) {
            this.setState({isUploading: true});

            let objSend = {
                title: this.state.videoEditing.title,
                autor: this.state.videoEditing.autor,
                tags: this.state.videoEditing.tags
            };

            ConnectionsService.put(`video/${this.state.videoEditing.id}`, objSend).then((response) => {
                this.getVideos("", () => {
                    this.setNotification('success','Video guardado exitósamente!');
                    this.setState({videoEditing: {}, isUploading: false});
                    this.setModalShowEdit(false);
                });


            }, (error) => {
                this.setNotification('danger','Error al intentar guardar video');
            });

        } else {
            notificationConfig.message = 'Título y autor son requeridos';
            store.addNotification(notificationConfig);
        }

    }

    setNotification(type, message) {
        this.notificationConfig.type = type;
        this.notificationConfig.message = message;
        store.addNotification(JSON.parse(JSON.stringify(this.notificationConfig)));
    }

    handleSubmit(data) {
        let notificationConfig = {
            title: "Atención!",
            message: 'Título, autor y archivo de video son requeridos',
            type: "warning",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 1000,
                onScreen: true
            }
        };
        if(this.state.videoInfo.title && this.state.videoInfo.autor && this.state.videoInfo.file.length) {
            this.setState({isUploading: true});

            ConnectionsService.uploadFile(this.state.videoInfo).then((response) => {
                this.setState({isUploading: false});
                notificationConfig.message = 'tu video fué subido correctamente';
                notificationConfig.type = "success";
                store.addNotification(notificationConfig);
                this.getVideos("");
                this.setModalShowDrop(false);
            },(error) => {
                this.setState({isUploading: false});
                notificationConfig.message = 'Error al intentar subir tu video';
                notificationConfig.type = "danger";
                store.addNotification(notificationConfig);
            });
        } else {
            notificationConfig.message = 'Título, autor y archivo de video son requeridos';
            store.addNotification(notificationConfig);
        }
    }

    handleSubmitVideo(data) {
        if(this.state.listPicked) {
            let objEnvio = this.prepareData();
            ConnectionsService.post(`video`,objEnvio).then((response) => {
                this.setState({listPicked:'', videosPicked: [], isIndividual: false});
                this.checkAll(false);
                this.notificationConfig.type = 'success';
                this.notificationConfig.message = 'Videos actualizados';
                store.addNotification(this.notificationConfig);
                this.getVideos("");
            }, (error) => {
                this.notificationConfig.type = 'danger';
                this.notificationConfig.message = 'Error al intentar actualizar videos';
            });
        }
    }

    prepareData() {
        let form = {
            playlist_id:this.state.listPicked,
            videos:this.state.videosPicked
        }
        return form;
    }

    handleChildren(data) {
        if(data.title && data.autor && data.file.length) {
            this.setState({buttonShowDrop: true,videoInfo: data});
        } else {
            this.setState({buttonShowDrop: false});
        }
    }

    handleVideoForm(data) {
        if(data.title && data.autor) {
            data.id = this.state.videoEditing.id
            this.setState({buttonShowEdit: true, videoEditing: data});
        } else {
            this.setState({buttonShowEdit: false});
        }
    }

    handleTags(value) {
        let searchTags = value.tags;

        this.setState({searchTags}, () => {
            this.getVideos(this.state.searchTerm);
        });
    }

    setModalShowDrop(value) {
        if(value) {
            this.getTagList(() => {
                this.setState({modalShowDrop: value});
            });
        } else {
            this.setState({modalShowDrop: value});
        }

    }

    setModalShowEdit(value) {
        this.setState({modalShowEdit: value, videoEditing: value ? this.state.videoEditing:{} });
    }

    handleEditVideo(video){
        this.getTagList(() => {
            this.setState({videoEditing: video}, () => {
                this.setModalShowEdit(true);
            });
        });
    }

    setModalShow(value) {
        this.setState({modalShow: value});
    }

    handlePageClick = (data) => {
        this.setState({ currentPage: data.selected }, () => {
            this.getVideos(this.state.searchTerm);
        });
    }

    render() {

        const listTagsDetails = this.state.videoDetails && this.state.videoDetails.tags.length && this.state.videoDetails.tags.map((tag, index) => {
            return (<span key={index}> {tag.name}{( index + 1) < this.state.videoDetails.tags.length ? ', ':''}</span>);
        });

        const listPlaylistsDetails = this.state.videoDetails && this.state.videoDetails.playlists.length && this.state.videoDetails.playlists.map((playlist, index) => {
            return (<span key={index}> {playlist.name}{( index + 1) < this.state.videoDetails.playlists.length ? ', ':''}</span>);
        });

        const listVideos = this.state.videos.length && this.state.videos.map(video =>
            <TableItem srcIcon="movielane"
                tags={video.tags}
                autor={video.autor}
                itemId={video.itemId}
                title={video.title}
                key={video.itemId}
                isChecked={video.isChecked}
                handleCheck={this.checkChild}>
                {
                    this.checkPermisionByRole("admin") && (
                        <button className="table-btn" id={`add-${video.itemId}`}
                            onClick={() => {this.handleVideoDetails(video)}}>
                            <SvgIcon srcIcon="info" width="35" height="35" fill="#7F60EA"/>
                            <span className="table-btn-span">Info</span>
                        </button>
                    )
                }
                {
                    this.checkPermisionByRole("admin") && (
                        <button className="table-btn" id={`add-${video.itemId}`}
                            onClick={() => this.handleEditVideo(video)}>
                            <SvgIcon srcIcon="edit" width="35" height="35" fill="#7F60EA"/>
                            <span className="table-btn-span">Editar</span>
                        </button>
                    )
                }
                <button className="table-btn" id={`add-${video.itemId}`}
                    onClick={() => {this.playVideo(video)}}>
                    <SvgIcon srcIcon="play" width="35" height="35" fill="#7F60EA"/>
                    <span className="table-btn-span">Reproducir</span>
                </button>
                {
                    // <button className="table-btn" id={`add-${video.itemId}`}
                    //     onClick={() => {this.checkSingle(video.itemId)}}>
                    //     <SvgIcon srcIcon="playlist" width="35" height="35" fill="#7F60EA"/>
                    //     <span className="table-btn-span">Agr lista</span>
                    // </button>
                }
                {
                    this.checkPermisionByRole("admin") && (
                        <button className="table-btn"
                            id={`delete-${video.itemId}`}
                            onClick={(e) => {this.deleteChild(e,video.itemId)}}
                            disabled={this.state.ajax}>
                            <SvgIcon srcIcon="close" width="35" height="35" fill="#7F60EA"/>
                            <span className="table-btn-span">Eliminar</span>
                        </button>
                    )
                }
            </TableItem>
        );

        const listNewList = this.state.newList.length && this.state.newList.map((list, index) => {
            return (
                <div className="DeviceEdit-row-active col-12 mb-3" key={index}>
                    <div className="row m-0 mb-3 d-flex h-100 align-items-center">
                        <div className="col max-width-35px">
                            <div className="custom-control custom-radio">
                                <input type="radio"
                                    id={list.id}
                                    name="customRadio"
                                    className="custom-control-input"
                                    onChange={this.handleRadio}/>
                                <label className="custom-control-label font-size-12 font-weight-500" htmlFor={list.id}>&nbsp;</label>
                            </div>
                        </div>
                        <div className="col max-width-50px">
                            <label className="m-0 w-100" htmlFor={list.id}>
                                <SvgIcon srcIcon="playlist" width="35"/>
                            </label>
                        </div>
                        <div className="col pl-0">
                            <div className="row m-0">
                                <div className="col-12">
                                    <label className="m-0 w-100" htmlFor={list.id}>
                                         <strong>
                                            {list.name}
                                        </strong>
                                    </label>
                                </div>
                                <div className="col-12">
                                     <label className="m-0 w-100" htmlFor={list.id}>
                                        {list.count_videos > 0 && `${list.count_videos} videos`}
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div className="Videos-container">
                {this.state.shouldRedirect && <Redirect to={this.state.redirectTo} />}

                <Dialog show={this.state.showVideoInfo}
                    onHide={() => this.setState({showVideoInfo: false})}
                    isUploading={false}>


                    {
                        this.state.videoDetails ? (
                            <div className='container'>
                                <div className='row'>
                                    <div className="col-12 text-center p-4">
                                        <h3>Información del video</h3>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-6 text-left p-4 offset-3">

                                        <div className='row p-2'>
                                            <div className="col-6 text-left Video-info-label">
                                                Canción
                                            </div>
                                            <div className="col-6 text-right Video-info-details">
                                                {this.state.videoDetails.title}
                                            </div>
                                        </div>

                                        <hr/>
                                        <div className='row p-2'>
                                            <div className="col-6 text-left Video-info-label">
                                                Autor
                                            </div>
                                            <div className="col-6 text-right Video-info-details">
                                                {this.state.videoDetails.autor || "Sin autor"}
                                            </div>
                                        </div>

                                        <hr/>
                                        <div className='row p-2'>
                                            <div className="col-6 text-left Video-info-label">
                                                Géneros
                                            </div>
                                            <div className="col-6 text-right Video-info-details">
                                                {this.state.videoDetails.tags.length ? listTagsDetails:("Sin géneros")}
                                            </div>
                                        </div>

                                        <hr/>

                                        <div className='row p-2'>
                                            <div className="col-6 text-left Video-info-label">
                                                Listas de reproducción
                                            </div>
                                            <div className="col-6 text-right Video-info-details">
                                                {this.state.videoDetails.playlists.length ? listPlaylistsDetails:("Sin listas")}
                                            </div>
                                        </div>

                                        <hr/>

                                    </div>
                                </div>
                            </div>
                        ):(null)
                    }
                </Dialog>

                <Dialog show={this.state.modalShowEdit}
                    onHide={() => this.setModalShowEdit(false)}
                    buttonTitle="Guardar"
                    buttonShow={this.state.buttonShowEdit}
                    isUploading={this.state.isUploading}
                    buttonCallBack={this.handleSubmitEdit}>

                    <VideoForm childrenData = { this.handleVideoForm }
                        isUploading = { this.state.isUploading }
                        tags = { this.state.tags }
                        videoEditing = { this.state.videoEditing }/>
                </Dialog>

                <Dialog show={this.state.modalShowDrop}
                    onHide={() => this.setModalShowDrop(false)}
                    buttonTitle="Subir video"
                    buttonShow={this.state.buttonShowDrop}
                    isUploading={this.state.isUploading}
                    buttonCallBack={this.handleSubmit}>
                    <DropVideo childrenData={this.handleChildren} isUploading={this.state.isUploading} tags={this.state.tags}/>
                </Dialog>

                <Dialog show={this.state.modalShow}
                    onHide={() => this.setModalShow(false)}
                    buttonTitle="Subir videos"
                    buttonShow={this.state.buttonShow}
                    isUploading={false}
                    buttonCallBack={this.handleSubmitVideo}>

                </Dialog>

                <Dialog show={this.state.modalShow}
                    onHide={this.closeModal}
                    buttonTitle="Guardar"
                    buttonShow={true}
                    isUploading={false}
                    buttonCallBack={this.handleAddAll}>
                    <h4 className="text-center w-100"><strong>Agregar a lista</strong></h4>
                    <h6 className="text-center w-100">Selecciona la lista a la que deseas agregar este video</h6>
                    <div className="p-3">
                        <Searcher updateInput={this.getAllList} placeholder="Buscar lista"/>
                    </div>

                    <div className="px-3 modal-lists-box">
                        { this.state.newList.length ? listNewList:(null) }
                        { this.state.isListAjax ? <LoadingBox animationType="loading"/>:(null) }
                    </div>
                    <div className="px-3">
                        { this.state.totalPL > this.state.perPagePL ? (
                            <ReactPaginate previousLabel={'Anterior'}
                                nextLabel={'Siguiente'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.pageCountPL}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}/>
                        ):(null) }
                    </div>



                </Dialog>

                <Dialog show={this.state.showPlayer}
                    onHide={() => this.setState({showPlayer: false})}
                    buttonTitle="Subir video"
                    buttonShow={false}
                    isUploading={false}>
                    <h4 className="text-center">{this.state.playingName}</h4>
                    <ReactPlayer controls={true} url={this.state.playingUrl} playing={this.state.showPlayer} width='100%' height='calc(100% - 50px)' />
                </Dialog>


                <div className="row m-0 pb-4">
                    <div className="col-12 col-md p-0 w-100 d-flex align-items-center">
                        <h4 className="mb-0">
                            Videos
                        </h4>
                    </div>

                    <div className="col-12 col-md-3">
                        <div className="row m-0">
                            <div className="col-12">
                                <Searcher updateInput={this.handleVideoSearch} placeholder="Buscar video"/>
                            </div>
                        </div>
                    </div>
                    { this.checkIfCanUpload()}

                    <div className="col-12">
                        <FiltersBox active={true}
                            showCloseBtn={false}
                            childrenData={this.handleTags}
                            onHide={() => this.setState({showFilters: false})}/>
                    </div>
                </div>

                <div className="row m-0">
                    <TableHeader updateCheck={this.checkAll} isCheckedAll={this.state.isCheckedAll}>
                        {
                            // <button className="table-btn" id="add-all" onClick={this.addAll}>
                            //     <SvgIcon srcIcon="playlist" width="35" height="35" fill="#7F60EA"/>
                            //     <span className="table-btn-span">Add lista</span>
                            // </button>
                        }
                        {
                            // this.checkPermisionByRole("admin") && (
                            //     <button className="table-btn" id="delete-all" onClick={this.deleteAll}>
                            //         <SvgIcon srcIcon="close" width="35" height="35" fill="#7F60EA"/>
                            //         <span className="table-btn-span">Eliminar</span>
                            //     </button>
                            // )
                        }
                    </TableHeader>
                </div>

                <div className={`row m-0 ${this.state.checkedVideos.length || this.state.isCheckedAll ? 'Videos-list-container-35':'Videos-list-container'}`}>
                    {this.state.videos.length && !this.state.isAjax ? listVideos:(null)}
                    { this.state.isAjax ? <LoadingBox animationType="loading"/>:(null)}
                </div>
                {
                    this.state.checkedVideos.length || this.state.isCheckedAll ? (
                        <div className="row m-0 gray-bg">
                            <div className="col p-0 text-center">
                                <button className="styless-btn w-100" onClick={this.addAll}>
                                    <SvgIcon srcIcon="playlist" width="35" height="35" fill="#7F60EA"/>
                                    <span className="table-btn-spanss">Añadir a lista</span>
                                </button>
                            </div>
                        </div>
                    ):(null)
                }

                {this.state.totalVideos > this.state.perPage ? (
                    <ReactPaginate previousLabel={'Anterior'}
                        nextLabel={'Siguiente'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}/>
                ):(null)}
            </div>
        );
    }
}
export default Videos;
