import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { store } from 'react-notifications-component';

import TimePicker from 'react-time-picker';

import CurrencyFormat from 'react-currency-format';

import Select from "react-select";
//import "react-select/dist/react-select.css";

import SvgIcon from '../svg-icon/SvgIcon';

import './DropPromo.css';

class DropPromo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            file:[],
            isFile : false,
            title: {
                touched: false,
                value: ''
            },
            timelapse: {
                touched: false,
                value: ''
            },
            start_time: {
                touched: false,
                value: '00:00'
            },
            end_time: {
                touched: false,
                value: '00:00'
            },
            formErrors: {
                title: '',
                timelapse: '',
                start_time: '',
                end_time: ''
            },
            videoTitle:'',
            titleValid: false,
            timelapseValid: false,
            start_timeValid: false,
            end_timeValid: false,
            formValid: false,
            devices: this.props.devices || [],
            multiValue: [],
            multiDayValue: [],
            percentCompleted: null,
            days: [
                {value:1, label: 'Lunes'},
                {value:2, label: 'Martes'},
                {value:3, label: 'Miércoles'},
                {value:4, label: 'Jueves'},
                {value:5, label: 'Viernes'},
                {value:6, label: 'Sábado'},
                {value:7, label: 'Domingo'}
            ]
        }

        this.data = {
            title: '',
            timelapse: '',
            start_time: '00:00',
            end_time: '00:00',
            file: [],
            devices_id:[],
            days:[]
        }
        this.handleInput = this.handleInput.bind(this);
        this.validateField = this.validateField.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.errorClass = this.errorClass.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.hourChanged = this.hourChanged.bind(this);

        this.handleMultiChange = this.handleMultiChange.bind(this);
        this.handleMultiDaysChange = this.handleMultiDaysChange.bind(this);


    }

    handleMultiChange(option) {
        let devices_id = [];

        if (option) {

            if (option.length) {
                option.forEach((op, i) => {
                    devices_id.push(op.value);
                });
            }

        }

        this.setState({multiValue: option || []});
        this.data.devices_id = devices_id;

        this.props.childrenData(this.data);
    }

    handleMultiDaysChange(option) {
        let days = [];
        if (option) {
            if (option.length) {
                option.forEach((op, i) => {
                    days.push(op.value);
                });
            }

        }

        this.setState({multiDayValue: option || []});
        this.data.days = days;

        this.props.childrenData(this.data);
    }

    handleInput(event) {
        const key = event.target.name;
        let value = event.target.value;

        this.data[key] = value;
        this.setState(
            {[key]: { value: value, touched: true}},
            () => { this.validateField(key, value) }
        );
        this.props.childrenData(this.data);
    }

    devicesFilter(devices = []) {
        let devicesFiltered = [];

        devicesFiltered = devices.map((device) => {
            return {value: device.id, label: device.name}
        });

        return devicesFiltered;
    }

    daysFilter(days = []) {
        let daysFiltered = [];

        daysFiltered = days.map((day) => {
            return {value: day.id, label: day.name}
        });


        return daysFiltered;
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let titleValid = this.state.titleValid;
        let timelapseValid = this.state.timelapseValid;
        let start_timeValid = this.state.start_timeValid;
        let end_timeValid = this.state.end_timeValid;
        let msg = '';
        switch(fieldName) {
            case 'end_time':
                end_timeValid = true;
                if(!value.length) {
                    msg = 'Hora final es requerida';
                    end_timeValid = false;
                }
                fieldValidationErrors.end_time = msg;
                break;
            case 'start_time':
                start_timeValid = true;
                if(!value.length) {
                    msg = 'Hora inicial es requerida';
                    start_timeValid = false;
                }
                fieldValidationErrors.start_time = msg;
                break;

            case 'title':
                titleValid = true;
                if(!value.length) {
                    msg = 'Título es requerido';
                    titleValid = false;
                }
                fieldValidationErrors.title = msg;
                break;
            case 'timelapse':
                timelapseValid = true;
                if(!value.length) {
                    msg = 'Lapso de tiempo es requerido';
                    timelapseValid = false;
                }
                fieldValidationErrors.timelapse = msg;
                break;
            default:
                break;
        }
        this.setState({formErrors: fieldValidationErrors, titleValid, timelapseValid, start_timeValid, end_timeValid}, this.validateForm);
    }

    validateForm() {
        this.setState({formValid: this.state.titleValid && this.state.timelapseValid && this.state.start_timeValid && this.state.end_timeValid});
    }

    errorClass(type) {
        return(this.state.formErrors[type].length === 0 || !this.state[type].touched ? '' : 'has-error');
    }

    getFile(file) {
        let msg = '';
        let error = false;
        const sizeLimit = 1000000000;
        let notificationConfig = {
            title: "Atención!",
            message: '',
            type: "warning",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 1000,
                onScreen: true
            }
        }
        if(file.length) {
            if(file[0].type === "video/mp4" || file[0].type === "video/x-matroska") {
                if(file[0].size <= sizeLimit) {
                    this.data.file = file;
                    this.setState({videoTitle: file[0].name});
                    this.setState({file});
                    this.setState({isFile: true});
                } else {
                    error = true;
                    msg = "Solo puedes subir videos de hasta 100 megas"
                }
            } else {
                msg = "Solo puedes subir videos del tipo mp4 ó mkv";
                error = true;
            }
            if (error) {
                notificationConfig.message = msg;
                store.addNotification(notificationConfig);
            }
        } else {
            notificationConfig.message = "Solo puedes cargar un video a la vez";
            store.addNotification(notificationConfig);
        }
    }

    buildTags (tags =  []) {
        let arr = [];

        tags.forEach((tag, i) => {
            arr.push(<option key={i} value={tag.id}>{tag.name}</option>);
        });

        return arr;
    }

    handleSelect(e) {
        // this.setState({[type]: e.target.value});
    }

    componentDidMount() {
        window.addEventListener('percentCompleted', () => {
            let percentCompleted = localStorage.getItem('percentCompleted');
            this.setState({percentCompleted});
        });
    }

    componentWillUnmount() {
        window.removeEventListener('percentCompleted', ()=>{});
    }

    hourChanged(e, key) {
        let item = {};
        item[key] = {
            value: e,
            touched: true
        }
        this.setState(item);
        this.data[key] = e;
        this.props.childrenData(this.data);
    }

    render() {
        const showLoader = this.state.percentCompleted ? (
            <div className="col-12 DropPromo-loader-container">
                <div className="row m-0">
                    <div className="col-8 offset-2 p-0 DropPromo-loader-background">
                        <div className="DropPromo-loader-progress-bar" style={{width: this.state.percentCompleted ? this.state.percentCompleted+ "%":0 + "%"}}> &nbsp;</div>
                        <span className="DropPromo-loader-progress-text" >{this.state.percentCompleted ? this.state.percentCompleted:0}%</span>
                    </div>
                </div>
            </div>
        ):(null);

        const uploading = this.props.isUploading ? (
            <div className="w-100 h-100 d-flex aling-items-center justify-content-center row m-0">
                <div className="col-12 d-flex align-self-end justify-content-center">
                    <SvgIcon srcIcon="loading" className="drop-zone-loading-icon" width="50px"/>
                </div>
                <div className="col-12 d-flex align-self-start justify-content-center">
                    Subiendo tu video...
                </div>
                {showLoader}
            </div>
        ):(null);
        const showDropZone = !this.state.isFile && !this.props.isUploading ? (
            <Dropzone multiple={false} onDrop={acceptedFiles => this.getFile(acceptedFiles)}>
                {({getRootProps, getInputProps}) => (
                    <section className="w-100 h-100 d-flex align-items-center justify-content-center drop-zone-section">
                        <div {...getRootProps()}
                            className="text-center drop-zone-div row m-0 w-100 h-100 d-flex align-items-center justify-content-center">
                            <input {...getInputProps()} />
                            <div className="col-12 d-flex align-self-end justify-content-center">
                                <SvgIcon srcIcon="movielane" width="50px" height="50px"/>
                            </div>
                            <div className="col-10 d-flex align-self-start justify-content-center">
                                <p>Arrastra y suelta el video aquí para subirlo</p>
                            </div>
                        </div>
                    </section>
                )}
            </Dropzone>):(null);

        const showFileForm = this.state.isFile && !this.props.isUploading ? (
            <div className="col-12 col-sm-10">
                <div className="row m-0">
                    <div className="col-12 text-center">
                        <SvgIcon srcIcon="movielane" width="50px" height="50px"/>
                    </div>
                    <div className="col-12 text-center">
                        <strong>{this.state.videoTitle}</strong>
                    </div>
                </div>

                <div className="row m-0 py-3">
                    <div className="col-12 text-left">
                        <div className="form-group mb-4">
                            <label htmlFor="title">Título</label>
                            <input type="text"
                                className={`form-control ${this.errorClass("title")}`}
                                id="title"
                                name="title"
                                placeholder="Título de promo"
                                value={this.state.title.value}
                                onChange={this.handleInput}/>
                            <span className={`${this.errorClass("title")}`}>
                                {this.state.formErrors.title}
                            </span>
                        </div>
                    </div>

                    <div className="col-12 text-left">
                        <div className="form-group mb-4">
                            <label htmlFor="timelapse">Lapso de tiempo (Cada cuantos segundos)</label>
                            <CurrencyFormat id='timelapse'
                                className={`form-control ${this.errorClass("timelapse")}`}
                                name="timelapse"
                                placeholder="EJ: 40"
                                maxLength='4'
                                format="####" mask=""
                                value={this.state.timelapse.value}
                                onValueChange={
                                    (data) => {
                                        let timelapse = this.state.timelapse;
                                        timelapse.value = data.value;
                                        timelapse.touched = true;
                                        this.data.timelapse = data.value;
                                        this.setState({timelapse});
                                        this.props.childrenData(this.data);
                                    }
                                }
                            />
                            <span className={`${this.errorClass("timelapse")}`}>
                                {this.state.formErrors.timelapse}
                            </span>
                        </div>
                    </div>

                    <div className="col-12 text-left">
                        <div className="row m-0">
                            <div className="col-12 col-lg-6 px-0 pr-lg-2 pl-lg-0">
                                <div className="form-group mb-4">
                                    <label htmlFor="start_time">Hora inicial</label>
                                    <TimePicker
                                        onChange={(e) => {this.hourChanged(e,'start_time')}}
                                        value={this.state.start_time.value}
                                        clearIcon={null}
                                        clockIcon={null}
                                        disableClock={true}
                                        className={`DropPromo-hourpicker form-control ${this.errorClass("start_time")}`}
                                        format='hh:mm a'
                                        id='start_time'/>

                                        <span className={`${this.errorClass("start_time")}`}>
                                            {this.state.formErrors.start_time}
                                        </span>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 px-0 pl-lg-2 pr-lg-0">
                                <div className="form-group mb-4">
                                    <label htmlFor="end_time">Hora final</label>
                                    <TimePicker
                                        onChange={(e) => {this.hourChanged(e,'end_time')}}
                                        value={this.state.end_time.value}
                                        clearIcon={null}
                                        clockIcon={null}
                                        disableClock={true}
                                        className={`DropPromo-hourpicker form-control ${this.errorClass("end_time")}`}
                                        format='hh:mm a'
                                        id='end_time'/>

                                    <span className={`${this.errorClass("end_time")}`}>
                                            {this.state.formErrors.end_time}
                                        </span>
                                </div>
                            </div>
                        </div>

                    </div>



                    <div className="col-12 text-left">
                        <div className="form-group mb-4">
                            <label htmlFor="devices_id">Dispositivos</label>
                            <Select id="devices_id"
                                name="devices_id"
                                placeholder="Dispositivos"
                                value={this.state.multiValue}
                                options={this.devicesFilter(this.state.devices)}
                                onChange={this.handleMultiChange}
                                isMulti={true}/>
                        </div>
                    </div>

                    <div className="col-12 text-left">
                        <div className="form-group mb-0">
                            <label htmlFor="days">Días</label>
                            <Select id="days"
                                name="days"
                                placeholder="Días"
                                value={this.state.multiDayValue}
                                options={this.state.days}
                                onChange={this.handleMultiDaysChange}
                                isMulti={true}/>
                        </div>
                    </div>
                </div>
            </div>
        ):(null);

        return (
            <div className="row h-100 d-flex align-items-center justify-content-center">
                {this.state.isFile && this.state.isUploading !== true ? showFileForm:(null)}
                {!this.state.isFile && this.state.isUploading !== true ? showDropZone:(null)}
                {this.props.isUploading ? uploading:(null)}
            </div>
        );
    }
}
export default DropPromo;
