import React, { Component } from 'react';

import './FiltersBox.css';
import SvgIcon from '../svg-icon/SvgIcon';

import Select from "react-select";
import ConnectionsService from '../../../core/services/ConnectionsService';


class FiltersBox extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: '',
            viewState: {
                active: this.props.active
            },
            viewClass: {
                active: 'FiltersBox-active',
                hidden: 'FiltersBox-hidden'
            },
            tags: [],
            multiValue: []
        }

        this.data = {
            tags:[]
        }

        this.sendToParent = this.sendToParent.bind(this);
        this.getTagList = this.getTagList.bind(this);
        this.handleMultiChange = this.handleMultiChange.bind(this);
        this.handleInput = this.handleInput.bind(this);
    }

    componentDidMount() {
        this.getTagList();
    }

    sendToParent () {
        this.props.buttonCallBack("submit");
    }

    handleInput(event) {
        const key = event.target.name;
        let value = event.target.value;

        this.data[key] = value;
        this.setState(
            {[key]: { value: value, touched: true}},
            () => { this.validateField(key, value) }
        );
        this.props.childrenData(this.data);
    }

    tagsFilter(tags = []) {
        let tagsFiltered = [];

        tagsFiltered = tags.map((tag) => {
            return {value: tag.id, label: tag.name}
        });


        return tagsFiltered;
    }

    handleMultiChange(option) {

        let tags = [];
        if (option) {

            if (option.length) {
                option.forEach((op, i) => {
                    tags.push(op.value);
                });
            }


        }
        this.setState({multiValue: option}, () => {
            this.data.tags = tags;
            this.props.childrenData(this.data);
        });



    }

    getTagList(callBack) {
        ConnectionsService.get('tag').then((response) => {
            if (response.data && response.data.length) {

                this.setState({tags: response.data}, () => {
                    if (callBack) {
                        callBack();
                    }
                });
            }
        });
    }

    render() {
        return (
            <div className={`container-fluid FiltersBox-main-container ${this.props.active ? this.state.viewClass.active:this.state.viewClass.hidden}`}>
                <div className="row">
                    <div className='col-12'>
                        <span>Filtrar por género</span>
                    </div>
                    <div className="col text-left">
                        <div className="form-group mb-0">
                            <Select id="tags"
                                name="tags"
                                placeholder="Géneros"
                                value={this.state.multiValue}
                                options={this.tagsFilter(this.state.tags)}
                                onChange={this.handleMultiChange}
                                isMulti={true}/>
                        </div>
                    </div>
                    { this.props.showCloseBtn && <div className='col-1 text-right pl-0 max-width-50px'>
                        <button className='styless-btn' onClick={this.props.onHide}><SvgIcon srcIcon="close" width="35" height="35" fill="#7F60EA"/></button>
                    </div>
                    }
                </div>
            </div>

        );
    }
}
export default FiltersBox;
