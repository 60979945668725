import React, { Component } from 'react'

import Select from "react-select";

import SvgIcon from '../svg-icon/SvgIcon';
import { ItemFilter } from '../../../core/helpers';

import './VideoForm.css';

class VideoForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title: {
                touched: false,
                value: ''
            },
            autor: {
                touched: false,
                value: ''
            },
            formErrors: {
                title: '',
                autor: ''
            },
            titleValid: false,
            autorValid: false,
            formValid: false,
            tags: this.props.tags || [],
            multiValue: [],
            videoEditing: this.props.videoEditing,
            videoTitle: ''
        }

        this.data = {
            title: '',
            autor: '',
            tags:[]
        }

        this.handleInput = this.handleInput.bind(this);
        this.validateField = this.validateField.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.errorClass = this.errorClass.bind(this);
        this.setupVideoToEdit = this.setupVideoToEdit.bind(this);
        this.handleMultiChange = this.handleMultiChange.bind(this);


    }

    setupVideoToEdit() {

        if(this.state.videoEditing) {
            if (this.state.videoEditing.title) {
                this.setState({title: {
                    touched: false,
                    value: this.state.videoEditing.title
                }, videoTitle: this.state.videoEditing.title});

                this.handleInput({target: {name: 'title', value: this.state.videoEditing.title}});
            }

            if (this.state.videoEditing.autor) {
                this.setState({autor: {
                    touched: false,
                    value: this.state.videoEditing.autor
                }});

                this.handleInput({target: {name: 'autor', value: this.state.videoEditing.autor}});
            }

            if (this.state.videoEditing.tags && this.state.videoEditing.tags.length && this.state.tags.length) {
                let items = [];

                this.state.videoEditing.tags.forEach((tag) => {
                    let result = ItemFilter(this.state.tags, 'id', tag.id);

                    if (result.length > 0) {
                        items.push(result[0]);
                    }

                });
                items = this.tagsFilter(items);
                this.setState({multiValue: items});
                this.handleMultiChange(items);

            }
        }
    }

    componentDidMount () {
        this.setupVideoToEdit()
    }

    handleMultiChange(option) {
        if (option) {
            let tags = [];
            if (option.length) {
                option.forEach((op, i) => {
                    tags.push(op.value);
                });
            }
            this.setState({multiValue: option});
            this.data.tags = tags;

            this.props.childrenData(this.data);
        }
    }



    handleInput(event) {
        const key = event.target.name;
        let value = event.target.value;

        this.data[key] = value;
        this.setState(
            {[key]: { value: value, touched: true}},
            () => { this.validateField(key, value) }
        );
        this.props.childrenData(this.data);
    }

    tagsFilter(tags = []) {
        let tagsFiltered = [];

        tagsFiltered = tags.map((tag) => {
            return {value: tag.id, label: tag.name}
        });


        return tagsFiltered;
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let titleValid = this.state.titleValid;
        let autorValid = this.state.autorValid;
        let msg = '';
        switch(fieldName) {
            case 'title':
                titleValid = true;
                if(!value.length) {
                    msg = 'Título es requerido';
                    titleValid = false;
                }
                fieldValidationErrors.title = msg;
                break;
            case 'autor':
                autorValid = true;
                if(!value.length) {
                    msg = 'Autor es requerido';
                    autorValid = false;
                }
                fieldValidationErrors.autor = msg;
                break;
            default:
                break;
        }
        this.setState({formErrors: fieldValidationErrors, titleValid, autorValid}, this.validateForm);
    }

    validateForm() {
        this.setState({formValid: this.state.titleValid && this.state.autorValid});
    }

    errorClass(type) {
        return(this.state.formErrors[type].length === 0 || !this.state[type].touched ? '' : 'has-error');
    }

    render() {

        const uploading = this.props.isUploading ? (
            <div className="w-100 h-100 d-flex aling-items-center justify-content-center row m-0">
                <div className="col-12 d-flex align-self-end justify-content-center">
                    <SvgIcon srcIcon="loading" className="drop-zone-loading-icon" width="50px"/>
                </div>
                <div className="col-12 d-flex align-self-start justify-content-center">
                    Actualizando tu video...
                </div>
            </div>
        ):(null);

        const formView = !this.props.isUpdating ? (
            <div className="col-12 col-sm-10 offset-sm-1">
                <div className="row m-0">
                    <div className="col-12 text-center">
                        <SvgIcon srcIcon="movielane" width="50px" height="50px"/>
                    </div>
                    <div className="col-12 text-center">
                        <strong>{this.state.videoTitle}</strong>
                    </div>
                </div>

                <div className="row m-0 py-3">
                    <div className="col-12 text-left">
                        <div className="form-group mb-4">
                            <label htmlFor="title">Título</label>
                            <input type="text"
                                className={`form-control ${this.errorClass("title")}`}
                                id="title"
                                name="title"
                                placeholder="Título de video"
                                value={this.state.title.value}
                                onChange={this.handleInput}/>
                            <span className={`${this.errorClass("title")}`}>
                                {this.state.formErrors.title}
                            </span>
                        </div>
                    </div>

                    <div className="col-12 text-left">
                        <div className="form-group mb-4">
                            <label htmlFor="autor">Autor</label>
                            <input type="text"
                                className={`form-control ${this.errorClass("autor")}`}
                                id="autor"
                                name="autor"
                                placeholder="Autor de video"
                                value={this.state.autor.value}
                                onChange={this.handleInput}/>
                            <span className={`${this.errorClass("autor")}`}>
                                {this.state.formErrors.autor}
                            </span>
                        </div>
                    </div>

                    <div className="col-12 text-left">
                        <div className="form-group mb-0">
                            <label htmlFor="gender">Género</label>
                            <Select id="tags"
                                name="tags"
                                placeholder="Géneros"
                                value={this.state.multiValue}
                                options={this.tagsFilter(this.state.tags)}
                                onChange={this.handleMultiChange}
                                isMulti={true}/>
                        </div>
                    </div>
                </div>
            </div>
        ):(null);

        return (
            <div className="row h-100 d-flex align-items-center justify-content-center">
                {this.props.isUploading ? uploading:(null)}
                {!this.props.isUploading ? formView:(null)}
            </div>
        );
    }
}
export default VideoForm;
