import React, { Component } from 'react';
import './DeviceEdit.css';

import { withRouter, Redirect } from 'react-router-dom';

import ConnectionsService from '../../../../core/services/ConnectionsService';
import AuthService from '../../../../core/services/AuthService';

import { ItemFilter, ParamsBuilder } from '../../../../core/helpers';

import Searcher from '../../../shared/searcher/Searcher';
import Dialog from '../../../shared/dialog/Dialog';
import SvgIcon from '../../../shared/svg-icon/SvgIcon';
import LoadingBox from '../../../shared/loading-box/LoadingBox';
import { store } from 'react-notifications-component';

import ReactPaginate from 'react-paginate';

class DeviceEdit extends Component {

    constructor(props) {
        super(props);
        this.functionBinder = this.functionBinder.bind(this);
        this.functionBinder(this);

        this.scheduleBase = {
            day_week: props.match.params.id,
            end_time: '00:00',
            start_time: '00:00',
            id: 0,
            playlist: {
                id: 0,
                name: '',
                count_videos: 0,
                total_duration: "00:00:00"
            }
        }

        this.state = {
            wrong: false,
            back: false,
            deviceId: props.match.params.device,
            day: props.match.params.id,
            deviceInfo: {
                id: 0,
                name: '',
                uid: ''
            },
            playlistsInfo:[],
            playlistsInfoBase: '',
            modalShow: false,
            scheduleShow: false,
            buttonShow: false,
            isUpdating: false,
            list: [],
            newList: [],
            newSchedule: [],
            pickedSchedule: -1,
            startHour: '',
            endHour: '',
            startMinutes: '',
            endMinutes: '',
            currentStep: 1,
            scheduleList: [],
            isGettingList: false,
            perPage: 50,
            currentPage: 0,
            pageCount: 0,
            totalLists: 0,
            searchTerm: '',
            isNew: false,


            test1: '',
            test2:''
        };

        this.days = [
            {name:"Lunes"},
            {name:"Martes"},
            {name:"Miércoles"},
            {name:"Jueves"},
            {name:"Viernes"},
            {name:"Sábado"},
            {name:"Domingo"}
        ];

        this.notificationConfig = {
            title: "Atención!",
            message: '',
            type: "warning",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 1000,
                onScreen: true
            }
        };
    }

    functionBinder(value) {
        this.checkParams = this.checkParams.bind(value);
        this.getDeviceInfo = this.getDeviceInfo.bind(value);
        this.getPlaylistInfo = this.getPlaylistInfo.bind(value);
        this.addNewList = this.addNewList.bind(value);
        this.setModalShow = this.setModalShow.bind(value);
        this.setScheduleShow = this.setScheduleShow.bind(value);
        this.handleAddList = this.handleAddList.bind(value);
        this.handleRadio = this.handleRadio.bind(value);
        this.handleDelete = this.handleDelete.bind(value);
        this.getAllList = this.getAllList.bind(value);
        this.handleSignout = this.handleSignout.bind(value);
        this.handleSelect = this.handleSelect.bind(value);
        this.handleAddNewSchedule = this.handleAddNewSchedule.bind(value);
        this.handleDeleteSchedule = this.handleDeleteSchedule.bind(value);
        this.setNotification = this.setNotification.bind(value);
        this.submitSchedule = this.submitSchedule.bind(value);
        this.handlePageClick = this.handlePageClick.bind(value);
        this.handleListsSearch = this.handleListsSearch.bind(value);
    }

    handleSelect(e,type) {
        this.setState({[type]: e.target.value});
    }

    handleAddList() {
        if(this.state.newList.length && this.state.scheduleList.length) {
            let index = this.state.pickedSchedule;
            let playlistsInfo = JSON.parse(JSON.stringify(this.state.playlistsInfo));
            let objSend = {
                playlist_id: this.state.scheduleList[0].id,
                start_time: `${playlistsInfo[index].start_time}`,
                end_time: `${playlistsInfo[index].end_time}`,
                day_week: this.state.day,
                device_id: this.state.deviceId
            };

            this.setNotification('default','Guardando lista de reproducción');

            ConnectionsService.put(`device/playlist/${playlistsInfo[index].id}`, objSend).then((response) => {
                this.setNotification('success','Lista de reproducción guardada exitósamente!');
                this.setState({pickedSchedule:-1, isNew: false});
                this.setModalShow(false);
                this.getPlaylistInfo();
            }, (error) => {
                this.setNotification('danger','Error al intentar guardar la lista de reproducción');
            });
        } else {
            this.setNotification('warning', 'Debes seleccionar una lista para continuar.')
        }
        // if(this.state.newList.length) {
        //     this.state.playlistsInfo.forEach((obj, index) => {
        //         if(obj.itemId) {
        //             let items = ItemFilter(this.state.newList, 'itemId', obj.itemId);
        //             if(items.length) {
        //                 let playlistsInfo = JSON.parse(JSON.stringify(this.state.playlistsInfo));
        //
        //                 let index = this.state.pickedSchedule;
        //                 playlistsInfo[index].playlist = items[0];
        //
        //                 let objSend = {
        //                     playlist_id: items[0].id,
        //                     start_time: `${playlistsInfo[index].start_time}`,
        //                     end_time: `${playlistsInfo[index].end_time}`,
        //                     day_week: this.state.day,
        //                     device_id: this.state.deviceId
        //                 }
        //
        //                 this.setNotification('default','Guardando lista de reproducción');
        //
        //                 if (playlistsInfo[index].id === 0) {
        //                     ConnectionsService.post(`device/playlist`, objSend).then((response) => {
        //                         this.setNotification('success','Lista de reproducción guardada exitósamente!');
        //                         this.setState({pickedSchedule:-1});
        //                         this.setModalShow(false);
        //                         this.getPlaylistInfo();
        //                     }, (error) => {
        //                         this.setNotification('danger','Error al intentar guardar la lista de reproducción');
        //                     });
        //                 } else {
        //                     ConnectionsService.put(`device/playlist/${playlistsInfo[index].id}`, objSend).then((response) => {
        //                         this.setNotification('success','Lista de reproducción guardada exitósamente!');
        //                         this.setState({pickedSchedule:-1});
        //                         this.setModalShow(false);
        //                         this.getPlaylistInfo();
        //                     }, (error) => {
        //                         this.setNotification('danger','Error al intentar guardar la lista de reproducción');
        //                     });
        //                 }
        //
        //             }
        //         }
        //     });
        // }
    }

    handleDelete(index) {
        let playlistsInfo = JSON.parse(JSON.stringify(this.state.playlistsInfo));
        let scheduleBase = JSON.parse(JSON.stringify(this.scheduleBase));

        playlistsInfo[index].playlist = scheduleBase.playlist;
        this.setState({playlistsInfo});
    }

    handleDeleteSchedule(index) {

        if (this.state.playlistsInfo[index].id !== 0) {
            this.setNotification('default', 'Eliminando horario...');

            ConnectionsService.delete(`device/playlist/${this.state.playlistsInfo[index].id}`).then((response) => {
                this.setNotification('success', 'Horario Eliminado');
                this.getPlaylistInfo();
            }, (error) => {
                this.setNotification('danger', 'Error al intentar eliminar horario');
            });
        } else {

            let playlistsInfo = JSON.parse(JSON.stringify(this.state.playlistsInfo));

            playlistsInfo.splice(index,1);
            this.setState({playlistsInfo});
        }
    }

    handleRadio(event) {
        let scheduleList = [{id: event.target.id, itemId: event.target.id.toString()}];
        this.setState({scheduleList});
    }

    setModalShow(value) {
        this.setState({modalShow: value});
    }

    openNewSchedule(value) {
        this.setState({
            startHour: '',
            endHour: '',
            startMinutes: '',
            endMinutes: '',
            scheduleShow: true,
            isNew: value,
            currentStep: 1,
            scheduleList: []
        });
    }

    setScheduleShow(value) {
        this.setState({
            startHour: '',
            endHour: '',
            startMinutes: '',
            endMinutes: '',
            scheduleShow: value,
            scheduleList: [],
            isNew: false
        });
    }

    checkParams() {
        let wrong = false;
        let day = this.props.match.params.id;
        let deviceId = this.props.match.params.device;
        if(isNaN(day) || isNaN(deviceId)) {
            wrong = true;
        }
        this.setState({wrong});
    }

    componentDidMount() {
        this.checkParams();
        if(!this.state.wrong) {
            this.getDeviceInfo();
            this.getPlaylistInfo();
            this.getAllList("");
        }
    }

    getDeviceInfo() {
        ConnectionsService.get('device').then((response) => {
            if(response.data) {
                let item = ItemFilter(response.data, 'id', parseInt(this.state.deviceId));
                if(item.length) {
                    this.setState({deviceInfo: item[0]});
                }
            }
        },(error) => {
            if(error.response.data) {
                if(!error.response.data.auth){
                    this.handleSignout();
                }
            }
        });
    }

    handleSignout() {
        AuthService.handleSignout();
        window.location.href = '/login';
    }

    getPlaylistInfo() {
        ConnectionsService.get(`device/${this.state.deviceId}/playlists?day_week=${this.state.day}`).then((response) => {
            if(response.data) {

                let items = response.data.sort((a, b) => {
                    let aHour = a.start_time.split(":");
                    let bHour = b.start_time.split(":");

                    return parseInt(aHour) - parseInt(bHour) ;
                })
                this.setState({playlistsInfo:items,playlistsInfoBase:JSON.stringify(items)});

            }
        },(error) => {
            if(error.response.data) {
                if(!error.response.data.auth){
                    this.handleSignout();
                }
            }
        });
    }

    addNewList(index) {
        this.setState({pickedSchedule: index, currentPage: 0});
        this.getAllList("");
        this.setModalShow(true);
    }

    handlePageClick = (data) => {
        this.setState({ currentPage: data.selected }, () => {
            this.getAllList(this.state.searchTerm);
        });
    }

    getAllList(text) {

        this.setState({isGettingList: true}, () => {
            let parameters = [{'page':this.state.currentPage + 1},{'s':text}];

            ConnectionsService.get(`playlist${ParamsBuilder(parameters)}`).then((response) => {

                let pageCount = 0;
                let totalLists = 0;

                if (response.data) {

                    if(response.data.playlists.length) {
                        response.data.playlists.forEach((list) => {
                            list.itemId = list.id.toString();
                        });
                    }

                    if (response.data.total > 0){
                        pageCount = Math.ceil(response.data.total / this.state.perPage)
                        totalLists = response.data.total;
                    }
                }

                let newList = response.data.playlists.map(obj => obj);
                newList.map(list => list.isChecked = false);
                this.setState({isGettingList: false, newList, pageCount, totalLists});
            }, error => console.log(error));

        });
    }

    buildOptions (length = 24) {
        let arr = [];

        for (let i = 0; i < length; i++) {
            let valueToString = i < 10 ? `0${i}`:i;
            arr.push(<option key={i} value={valueToString}>{valueToString}</option>)
        }

        return arr;
    }

    handleListsSearch(text) {
        this.setState({searchTerm: text, currentPage: 0}, () => {
            this.getAllList(text);
        });

    }

    setNotification(type, message) {
        this.notificationConfig.type = type;
        this.notificationConfig.message = message;
        store.addNotification(JSON.parse(JSON.stringify(this.notificationConfig)));
    }

    handleAddNewSchedule() {
        if (this.state.startHour && this.state.startMinutes && this.state.endHour && this.state.endMinutes) {
            this.setNotification('default','Guardando horario...');

            let objSend = {
                id: 0,
                start_time: `${this.state.startHour}:${this.state.startMinutes}`,
                end_time: `${this.state.endHour}:${this.state.endMinutes}`,
                day_week: this.state.day,
                name: `${this.state.startHour}:${this.state.startMinutes} - ${this.state.endHour}:${this.state.endMinutes}`,
                playlist: {
                    id: 0,
                    name: '',
                    count_videos: 0,
                    total_duration: "00:00:00"
                }
            }
            let playlistsInfo = JSON.parse(JSON.stringify(this.state.playlistsInfo));
            playlistsInfo.push(objSend);
            playlistsInfo = playlistsInfo.sort((a,b) => {
                let aHour = a.start_time.split(':');
                let bHour = b.start_time.split(':');
                return parseInt(aHour) - parseInt(bHour) ;
            });
            this.setState({playlistsInfo});

            this.setScheduleShow(false);

        } else {
            this.setNotification('warning','Debes llenar todos los campos para continuar');
        }
    }

    _next = () => {
        let currentStep = this.state.currentStep
        currentStep = currentStep >= 1? 2: currentStep + 1
        this.setState({
            currentStep: currentStep
        })
    }

    _prev = () => {
        let currentStep = this.state.currentStep
        currentStep = currentStep <= 1? 1: currentStep - 1
        this.setState({
            currentStep: currentStep
        })
    }

    previousButton() {
        let currentStep = this.state.currentStep;
        if(currentStep !==1) {
            return (
                <button
                className="btn btn-secondary"
                type="button" onClick={this._prev}>
                    Anterior
                </button>
            )
        }
        return null;
    }

    nextButton(){
        let currentStep = this.state.currentStep;
        if (currentStep < 2 && this.state.startHour && this.state.startMinutes && this.state.endHour && this.state.endMinutes) {

            if(currentStep < 2) {
                return (
                    <button className="btn btn-primary"
                    type="button" onClick={this._next}>
                        Siguiente
                    </button>
                )
            }

        }
        return null;
    }

    submitSchedule () {
        if(this.state.startHour && this.state.startMinutes && this.state.endHour && this.state.endMinutes && this.state.scheduleList.length) {
            if(this.state.isNew) {
                let objSend = {
                    playlist_id: this.state.scheduleList[0].id,
                    start_time: `${this.state.startHour}:${this.state.startMinutes}`,
                    end_time: `${this.state.endHour}:${this.state.endMinutes}`,
                    day_week: this.state.day,
                    device_id: this.state.deviceId
                }

                this.setNotification('default','Guardando lista de reproducción');

                ConnectionsService.post(`device/playlist`, objSend).then((response) => {
                    this.setNotification('success','Lista de reproducción guardada exitósamente!');
                    this.setState({pickedSchedule:-1,scheduleList: [], isNew: false});
                    this.setScheduleShow(false);
                    this.getPlaylistInfo();
                }, (error) => {
                    this.setNotification('danger','Error al intentar guardar la lista de reproducción');
                });
            }
        } else {
            this.setNotification('warning', 'Debes seleccionar una lista antes de continuar');
        }
    }

    handleChangez = (event) => {
        if (event && event.target) {
            const {name, value} = event.target
            this.setState({
                [name]: value
            })
        }
    }

    step1(currentStep) {
        if (currentStep !== 1) {
            return null
        }
        return(

            <div className="px-3 row m-0">
                <div className="col-12">
                    <div className="col-12 mt-3">
                        <h4 className="text-center w-100"><strong>Nuevo horario</strong></h4>
                        <h6 className="text-center w-100">Selecciona el horario que deseas para tus listas de reproducción<br/><small>Debes seleccionar tu horario en formato de 24 horas.</small></h6>

                        <div className="px-3 row m-0 modal-lists-box3">
                            <div className="col-12 p-5">

                                <div className="row mt-2 mx-0">
                                    <div className="col-12 text-center"><strong>Horario de inicial</strong></div>
                                </div>

                                <div className="row mt-2 mx-0">
                                    <div className="col-6 text-right">
                                        <select value={this.state.startHour} name="" id="" onChange={(e) => this.handleSelect(e, "startHour")}>
                                            <option value="">Hora</option>
                                            {this.buildOptions()}
                                        </select>
                                    </div>
                                    <div className="col-6 text-left">
                                        <select value={this.state.startMinutes} name="" id="" onChange={(e) => this.handleSelect(e, "startMinutes")}>
                                            <option value="">Minutos</option>
                                            {this.buildOptions(60)}
                                        </select>
                                    </div>
                                </div>

                                <div className="row mt-4 mx-0">
                                    <div className="col-12 text-center"><strong>Horario de final</strong></div>
                                </div>

                                <div className="row mt-2 mx-0">
                                    <div className="col-6 text-right">
                                        <select value={this.state.endHour} name="" id="" onChange={(e) => this.handleSelect(e, "endHour")}>
                                            <option value="">Hora</option>
                                            {this.buildOptions()}
                                        </select>
                                    </div>
                                    <div className="col-6 text-left">
                                        <select value={this.state.endMinutes} name="" id="" onChange={(e) => this.handleSelect(e, "endMinutes")}>
                                            <option value="">Minutos</option>
                                            {this.buildOptions(60)}
                                        </select>
                                    </div>
                                </div>

                            </div>


                        </div>

                    </div>
                </div>
            </div>
        );
    }

    render() {
        const listSchedules = this.state.playlistsInfo.length ? this.state.playlistsInfo.map((play,index) => {
            return (
                <div className="col-12" key={index}>
                    <div className="row m-0">
                        <div className="col d-flex align-items-center DeviceEdit-hours-color py-4 px-0 max-width-30px mouse-pointer"
                            onClick={() => {this.handleDeleteSchedule(index)}}
                            title="Borrar horario">
                            <SvgIcon srcIcon="close" width="30"/>
                        </div>
                        <div className="col-11 d-flex align-items-center DeviceEdit-hours-color py-4">
                            <SvgIcon srcIcon="clock" width="20"/>
                            <strong className="pl-2">{play.start_time} - {play.end_time} hrs</strong>
                        </div>
                        { play.playlist.id === 0 && (
                            <div onClick={() => {this.addNewList(index)}}
                            className="mouse-pointer unselect-text col-12 DeviceEdit-row-default DeviceEdit-hours-color d-flex align-items-center justify-content-center">
                                <SvgIcon srcIcon="playlist" width="25"/>
                                <strong className="pl-2">Nueva lista</strong>
                            </div>
                        )}
                        { play.playlist.id !== 0  && (
                            <div className="col-12 DeviceEdit-row-active">
                                <div className="row m-0 h-100 d-flex align-items-center pr-3">
                                    <div className="col max-width-50px">
                                        <SvgIcon srcIcon="playlist" width="35px"/>
                                    </div>
                                    <div className="col">
                                        <div className="row m-0">
                                            <div className="col-12">
                                                <strong>
                                                    {play.playlist.name}
                                                </strong>
                                            </div>
                                            <div className="col-12">
                                                {play.playlist.count_videos >= 0 ? `${play.playlist.count_videos} videos`:(null)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col max-width-50px">
                                        <button className="table-btn" onClick={() => {this.handleDelete(index)}}>
                                            <SvgIcon srcIcon="close" width="35" height="35" fill="#7F60EA"/>
                                            <span className="table-btn-span">Eliminar</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
        )
        }):(null);

        const listNewList = this.state.newList.length && this.state.newList.map((list, index) => {
            return (
                <div className="DeviceEdit-row-active col-12 mb-3" key={index+1}>
                    <div className="row m-0 mb-3 d-flex h-100 align-items-center">
                        <div className="col max-width-35px">
                            <div className="custom-control custom-radio">
                                <input type="radio"
                                    id={list.id}
                                    name="customRadio"
                                    className="custom-control-input"
                                    onChange={this.handleRadio}/>
                                <label className="custom-control-label font-size-12 font-weight-500" htmlFor={list.id}>&nbsp;</label>
                            </div>
                        </div>
                        <div className="col max-width-50px">
                            <label className="m-0 w-100" htmlFor={list.id}>
                                <SvgIcon srcIcon="playlist" width="35"/>
                            </label>
                        </div>
                        <div className="col pl-0">
                            <div className="row m-0">
                                <div className="col-12">
                                    <label className="m-0 w-100" htmlFor={list.id}>
                                         <strong>
                                            {list.name}
                                        </strong>
                                    </label>
                                </div>
                                <div className="col-12">
                                     <label className="m-0 w-100" htmlFor={list.id}>
                                        {list.count_videos > 0 && `${list.count_videos} videos`}
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            );
        });

        const step2 = [1].map((index) => {
            return (
                <div className="pt-5" key={index}>
                    <h4 className="text-center w-100"><strong>Agregar lista1</strong></h4>
                    <h6 className="text-center w-100">Selecciona la lista que deseas agregar a este horario.</h6>
                    <div className="p-3">
                        <Searcher updateInput={this.handleListsSearch} placeholder="Buscar lista"/>
                    </div>
                </div>
            );
        })

        let progressWidth = {width: `${this.state.currentStep * 50}%`};
        return (
            <div className="DeviceEdit-container">
                <Dialog show={this.state.modalShow}
                    onHide={() => this.setModalShow(false)}
                    buttonTitle="Guardar"
                    buttonShow={true}
                    isUploading={false}
                    buttonCallBack={this.handleAddList}>
                    <h4 className="text-center w-100"><strong>Agregar lista2</strong></h4>
                    <h6 className="text-center w-100">Selecciona la lista que deseas agregar a este horario.</h6>
                    <div className="p-3">
                        <Searcher updateInput={this.handleListsSearch} placeholder="Buscar lista"/>
                    </div>
                    <div className="px-3 row m-0 modal-lists-box">
                        {this.state.newList.length && !this.state.isGettingList ? listNewList:(null)}
                        {this.state.isGettingList ? <LoadingBox animationType="loading"/>:(null)}
                    </div>

                    <div className="px-3">
                        { this.state.totalLists > this.state.perPage ? (
                                <ReactPaginate previousLabel={'Anterior'}
                                    nextLabel={'Siguiente'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}/>

                            ):(null)
                        }
                    </div>
                </Dialog>

                <Dialog show={this.state.scheduleShow}
                    onHide={() => this.setScheduleShow(false)}
                    buttonShow={false}
                    isUploading={false}
                    buttonCallBack={this.handleAddNewSchedule}>
                    <div className="progress">
                        <div className="progress-bar progress-bar-success" role="progressbar" aria-valuenow="1" aria-valuemin="1" aria-valuemax="2" style={progressWidth}>
                            Paso {this.state.currentStep} de 2
                        </div>
                    </div>
                    {this.step1(this.state.currentStep)}
                    { this.state.currentStep === 2 && step2}

                    { this.state.currentStep === 2 && (
                            <div className="px-3 row m-0 modal-lists-box2">
                                {this.state.newList.length && !this.state.isGettingList ? listNewList:(null)}
                                {this.state.isGettingList ? <LoadingBox animationType="loading"/>:(null)}
                            </div>
                        )
                    }
                    <div className="px-3">
                        { this.state.currentStep === 2 && this.state.totalLists > this.state.perPage ? (

                            <ReactPaginate previousLabel={'Anterior'}
                                nextLabel={'Siguiente'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}/>

                            ):(null)
                        }
                    </div>
                    <div className="row m-0 modal-footer">
                        <div className="col-12">
                            <div className="px-0 py-2 text-center">

                                {this.nextButton()}

                                {this.state.currentStep === 2 && (<button className="btn btn-primary"
                                type="button" onClick={() => {this.submitSchedule()}}>
                                    Guardar horarios
                                </button>)}
                            </div>
                        </div>
                    </div>


                </Dialog>

                { this.state.wrong &&
                    <Redirect to='/'/>
                }
                { this.state.back &&
                    <Redirect to='/dispositivos' />
                }
                <div className="row m-0 pb-4">
                    <div className="col-1 col-sm p-0 w-100 d-flex align-items-center max-width-35px">
                        <button className="btn btn-default p-0" onClick={() => {this.setState({back:true})}}>
                            <SvgIcon srcIcon="left" width="35px"/>
                        </button>
                    </div>
                    <div className='col-11 col-sm pl-2 pr-0 col-md w-100 d-flex align-items-center'>
                        <h4 className="mb-0">
                            Dispositivos
                        </h4>
                    </div>
                </div>

                <div className="row m-0 DeviceEdit-box mb-4">
                    <div className="col-12">
                        <div className="row m-0 DeviceEdit-header d-flex">
                            <div className="col-6">
                                <div className="row m-0 d-flex h-100">
                                    <div className="col-12 pl-4 d-flex align-self-end">
                                        <p className='h5 mb-0 text-primary'>
                                            <strong>
                                                {this.state.deviceInfo.name}
                                            </strong>
                                        </p>
                                    </div>
                                    <div className="col-12 pl-4 d-flex align-self-start">
                                        <p className='h5 mb-0'>
                                            <strong>
                                                Programación de {this.days[parseInt(this.state.day) - 1].name}
                                            </strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row m-0 h-100 d-flex">
                                    <div className="col-6 offset-6 text-right align-self-center">
                                        <button type="button"
                                            className="btn-standard w-100 success-bg toggle-content"
                                             onClick={() => {this.openNewSchedule(true)}}>
                                            <span>Crear horario</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {listSchedules}
                </div>
            </div>
        );
    }
}

export default withRouter(DeviceEdit);
