import React, { Component } from 'react';
import './Sidebar.css';
import SvgIcon from '../../shared/svg-icon/SvgIcon';
import Dialog from '../../shared/dialog/Dialog';
import DropVideo from '../../shared/drop-video/DropVideo';
import SidebarItems from './sidebar-items/SidebarItems';
import { store } from 'react-notifications-component';
import ConnectionsService from '../../../core/services/ConnectionsService';

class Sidebar extends Component {

    constructor(props) {
        super(props);
        this.handleCloser = this.handleCloser.bind(this);
        this.setModalShow = this.setModalShow.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChildren = this.handleChildren.bind(this);
        this.getTagList = this.getTagList.bind(this);

        this.menuConfig = require('./sidebar-config.json');
        this.state = {
            menu: this.menuConfig,
            screenWidth: window.innerWidth,
            modalShow: false,
            buttonShow: false,
            videoInfo: {
                title:'',
                autor:'',
                file: []
            },
            tags: [],
            isUploading: false,
        }
    }

    componentDidMount() {

        if (this.menuConfig.items.length) {
            this.menuConfig.items.forEach((item) => {
                item.canActivate = this.checkIfCanActivate(item.roles);
            });

            this.setState({ menu: this.menuConfig });
        }

    }

    checkIfCanActivate (whom) {

        let token = JSON.parse(localStorage.getItem('token'));

        if (whom.length && token && token.role) {
            let items = whom.filter(who => who === token.role)

            return items.length > 0
        }
        return false
    }

    getTagList() {
        ConnectionsService.get('tag').then((response) => {
            if (response.data && response.data.length) {
                this.setState({tags: response.data, modalShow: true});
            }
        });
    }

    handleCloser() {
        if(this.state.screenWidth < 768) {
            this.props.updateShared(this.props.sharedVar ? 'toggle-on':'toggle-off');
        }
    }

    handleSubmit(data) {
        let notificationConfig = {
            title: "Atención!",
            message: 'Título, autor y archivo de video son requeridos',
            type: "warning",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 1000,
                onScreen: true
            }
        };
        if(this.state.videoInfo.title && this.state.videoInfo.autor && this.state.videoInfo.file.length) {
            this.setState({isUploading: true});
            ConnectionsService.uploadFile(this.state.videoInfo).then((response) => {
                this.setState({isUploading: false});
                notificationConfig.message = 'tu video fué subido correctamente';
                notificationConfig.type = "success";
                store.addNotification(notificationConfig);
                this.props.sendToUpload(true);
                this.setModalShow(false);
            },(error) => {
                this.setState({isUploading: false});
                notificationConfig.message = 'Error al intentar subir tu video';
                notificationConfig.type = "danger";
                store.addNotification(notificationConfig);
            });
        } else {
            notificationConfig.message = 'Título, autor y archivo de video son requeridos';
            store.addNotification(notificationConfig);
        }
    }

    handleChildren(data) {
        if(data.title && data.autor && data.file.length) {
            this.setState({buttonShow: true});
            this.setState({videoInfo: data});
        } else {
            this.setState({buttonShow: false});
        }
    }

    setModalShow(value) {
        if (value) {
            this.getTagList();
        } else {
            this.setState({modalShow: value});
        }
    }

    checkIfCanUpload () {
        let token = JSON.parse(localStorage.getItem('token'));

        if (token && token.role === "admin") {
            return(
                <div className="col-12 p-0">
                    <div className="row m-0 px-3">
                        <div className="col-12 px-0 py-3 border-bottom-d8d8d8">
                            <button type="button" className="btn-standard w-100 primary-bg toggle-content" onClick={() => this.setModalShow(true)}>
                                <SvgIcon srcIcon="upload" fill="white"/><span>Subir video</span>
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        return null
    }

    render() {
        // const listItems = this.state.menu.items.map((item) =>
        //     <SidebarItems item={item} key={item.key.toString()} sharedVar={this.props.sharedVar} updateCloser={this.handleCloser}/>
        // );

        const listItems = this.state.menu.items.map((item) => {
            let token = JSON.parse(localStorage.getItem('token'));

            if (token && token.role) {
                let items = item.roles.filter( rol => rol === token.role);

                if (items.length) {
                    return (<SidebarItems item={item} key={item.key.toString()} sharedVar={this.props.sharedVar} updateCloser={this.handleCloser}/>)
                }
            }

            return null
        });
        return (
            <div className={`Sidebar-container ${this.props.sharedVar}`}>
                <Dialog show={this.state.modalShow}
                    onHide={() => this.setModalShow(false)}
                    buttonTitle="Subir video"
                    buttonShow={this.state.buttonShow}
                    isUploading={this.state.isUploading}
                    buttonCallBack={this.handleSubmit}>
                    <DropVideo childrenData={this.handleChildren} isUploading={this.state.isUploading} tags={this.state.tags}/>
                </Dialog>

                <div className="d-inline d-md-none text-right row m-0">
                    <div className="col-12 padding-0">
                        <button className="closer-btn btn"
                            id="toggler-btn"
                            type="button"
                            onClick={this.handleCloser}>
                            <span className="dark-blue-text">
                                <SvgIcon srcIcon="close"/>
                            </span>
                        </button>
                    </div>
                </div>

                <div className="row m-0 sidebar-menu-container">
                    {
                        this.checkIfCanUpload()
                    }
                    <div className="col-12">
                        <ul className="sidebar-items padding-top-10">
                            {listItems}
                        </ul>
                    </div>
                </div>

            </div>
        );
    }
}
export default Sidebar;
