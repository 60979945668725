import React, { Component } from 'react';
import AuthService from '../../../../core/services/AuthService';
import './User.css';
import icon from '../../../../assets/images/icons/icn_user_default.png';
import SvgIcon from '../../../shared/svg-icon/SvgIcon';
class User extends Component {
    constructor() {
        super();
        this.state = {
            showMenu: false,
            baseName: this.getBasename(),
            logout: false
        };
        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.handleSignout = this.handleSignout.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    showMenu(event) {
        event.preventDefault();

        this.setState({ showMenu: true }, () => {
            document.addEventListener('click', this.closeMenu);
        });
    }

    closeMenu(event) {
        if (!this.dropdownMenu.contains(event.target)) {
            this.setState({ showMenu: false }, () => {
                document.removeEventListener('click', this.closeMenu);
            });
        }
    }

    handleClose() {
        this.setState({ showMenu: false }, () => {
            document.removeEventListener('click', this.closeMenu);
        });
    }

    getBasename() {
        return window.location.hostname === 'localhost' ? '':'';
    }

    handleSignout() {
        AuthService.handleSignout();
        this.setState({logout: true});
        window.location.href = `/`;
        window.location.reload();
    }

    render() {
        return (
            <div className="User-container p-relative">
                <button onClick={this.showMenu} className="User-icon User-icn-btn">
                    <img src={`${icon}`} className="User-icon" alt="user"/>
                </button>

                {
                    this.state.showMenu ? (
                        <div className={`User-menu ${this.state.showMenu ? "active":""}`} ref={(element) => {this.dropdownMenu = element;}}>
                            <button className="User-closer d-inline-block d-md-none" onClick={this.handleClose}>
                                <SvgIcon srcIcon="close"/>
                            </button>
                            <button className="User-menu-item" onClick={this.handleSignout}>
                                <SvgIcon srcIcon="signout"/>
                                <span>Cerrar sesión</span>
                            </button>
                        </div>
                    ): (
                        null
                    )
                }
            </div>
        );
    }
}

export default User;
