// import React from 'react';
// import ReactDOM from 'react-dom';
//
// import App from './App';
//
// /**
//  * BOOTSTRAP LIBS
//  */
// import 'bootstrap/dist/css/bootstrap.min.css';
// import $ from 'jquery';
// import Popper from 'popper.js';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
// /**
//  * // BOOTSTRAP LIBS
//  */
//  import './index.css';
// import * as serviceWorker from './serviceWorker';
//
// ReactDOM.render(<App />, document.getElementById('root'));
//
// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

import React, { Component } from 'react'
import ReactDOM from 'react-dom'



/**
 * BOOTSTRAP LIBS
 */
import 'bootstrap/dist/css/bootstrap.min.css';
//import $ from 'jquery';
//import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
/**
 * // BOOTSTRAP LIBS
 */
 import './index.css';
import * as serviceWorker from './serviceWorker';

import {
  Route,
  BrowserRouter as Router,
  Switch
} from 'react-router-dom'
// import {
//   Route,
//   NavLink,
//   BrowserRouter as Router,c
//   Switch,
// } from 'react-router-dom'
//import PropTypes from 'prop-types';
import App from './App';
import LoginForm from './components/auth/login/LoginForm';
import NotFound from './components/notfound/NotFound';
import { history } from './core/helpers';

import AuthService from './core/services/AuthService';

class RoutingIndex extends Component {

    constructor() {
        super();
        this.state = {
            baseName: this.getBasename()
        }
    }

    requireAuth(nextState, replace){
        if(!AuthService.isAuthenticated()) {
            history.push(`/login`);
        }
    }

    checkIfCanActivate (whom) {
        let token = JSON.parse(localStorage.getItem('token'));

        if (whom.length && token && token.role) {
            let items = whom.filter(who => who === token.role)

            return items.length > 0
        }
        return false
    }

    getRoutes() {
        // let routes = [
        //     {
        //         route: <Route key="0" path={`/`} exact component={App} onEnter={this.requireAuth()}/>,
        //         canActivate: this.checkIfCanActivate(['admin','customer'])
        //     },
        //     {
        //         route: <Route key="1" path={`/listas`} exact component={App} onEnter={this.requireAuth()}/>,
        //         canActivate: this.checkIfCanActivate(['admin'])
        //     },
        //     {
        //         route: <Route key="2" path={`/dispositivos`} exact component={App} onEnter={this.requireAuth()}/>,
        //         canActivate: this.checkIfCanActivate(['admin'])
        //     },
        //     {
        //         route: <Route key="3" path={`/dispositivos-edit/:device/:id`} exact component={App} onEnter={this.requireAuth()}/>,
        //         canActivate: this.checkIfCanActivate(['admin'])
        //     },
        //     {
        //         route: <Route key="4" path={`/dispositivos-cliente`} exact component={App} onEnter={this.requireAuth()}/>,
        //         canActivate: this.checkIfCanActivate(['customer'])
        //     }
        // ]
        return (
            <Switch>
                {
                    // routes.map( (route) => {
                    //     if (route.canActivate) {
                    //         return route.route
                    //     }
                    //     return null
                    // })
                }
                <Route path={`/`} exact component={App} onEnter={this.requireAuth()}/>
                <Route path={`/listas`} exact component={App} onEnter={this.requireAuth()}/>
                <Route path={`/promos`} exact component={App} onEnter={this.requireAuth()}/>
                <Route path={`/dispositivos`} exact component={App} onEnter={this.requireAuth()}/>
                <Route path={`/dispositivos-edit/:device/:id`} exact component={App} onEnter={this.requireAuth()}/>
                <Route path={`/dispositivos-cliente`} exact component={App} onEnter={this.requireAuth()}/>
                <Route path={`/login`} component={LoginForm}/>
                <Route path="*" component={NotFound}/>
            </Switch>
        )
    }

    getBasename() {
        return window.location.hostname === 'localhost' ? '':'';
    }

    render() {
        return (
            <Router basename=''>
                {this.getRoutes()}
            </Router>
        )
    }
}

ReactDOM.render(<RoutingIndex/>, document.getElementById('root'));
serviceWorker.unregister();
