import React, { Component } from 'react';
import './App.css';
import {
    withRouter,
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';

import Header from './components/layout/header/Header';
import Sidebar from './components/layout/sidebar/Sidebar';
import Videos from './components/layout/videos/Videos';
import Lists from './components/layout/lists/Lists';

import Promo from './components/layout/promo/Promo';
import Devices from './components/layout/devices/Devices';
import DeviceEdit from './components/layout/devices/device-edit/DeviceEdit';
import DevicesCustomer from './components/layout/devices/devices-customer/DevicesCustomer';
import NotFound from './components/notfound/NotFound';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas, faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'

import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

library.add(fab, fas, faCheckSquare, faCoffee)


class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sharedVar:'toggle-on',
            upload: false,
            baseName: this.getBasename()
        };

        this.updateData = this.updateData.bind(this);
        this.uploadList = this.uploadList.bind(this);
    }

    updateData(shared_value) {
        this.setState({sharedVar: shared_value});
    }

    uploadList(value) {
        this.setState({upload: value});
    }

    getBasename() {
        return window.location.hostname === 'localhost' ? '':'';
    }

    checkIfCanActivate (whom) {
        let token = JSON.parse(localStorage.getItem('token'));

        if (whom.length && token && token.role) {
            let items = whom.filter(who => who === token.role)

            return items.length > 0
        }
        return false
    }

    getRoutes() {
        let routes = [
            {
                route: <Route key="0" exact path={`/`} component={() => <Videos sharedVar={this.state.sharedVar}/>} uploaded={this.state.upload}/>,
                canActivate: this.checkIfCanActivate(["admin","customer"])
            },
            {
                route: <Route key="1" path={`/listas`} component={() => <Lists sharedVar={this.state.sharedVar}/>}/>,
                canActivate: this.checkIfCanActivate(["admin"])
            },
            {
                route: <Route key="2" path={`/promos`} component={() => <Promo sharedVar={this.state.sharedVar}/>}/>,
                canActivate: this.checkIfCanActivate(["admin"])
            },
            {
                route: <Route key="3" path={`/dispositivos`} component={() => <Devices sharedVar={this.state.sharedVar}/>} />,
                canActivate: this.checkIfCanActivate(["admin"])
            },
            {
                route: <Route key="4" path={`/dispositivos-edit/:device/:id`} component={() => <DeviceEdit sharedVar={this.state.sharedVar}/>} />,
                canActivate: this.checkIfCanActivate(['admin'])
            },
            {
                route: <Route key="5" path={`/dispositivos-cliente`} component={() => <DevicesCustomer sharedVar={this.state.sharedVar}/>} />,
                canActivate: this.checkIfCanActivate(['customer'])
            }
        ]
        return (
            <Switch>
                {
                    routes.map( (route) => {
                        if (route.canActivate) {
                            return route.route
                        }
                        return null
                    })
                }
                <Route path="*" component={NotFound} />
            </Switch>
        )
    }

    render() {
        return (
            <Router basename=''>
                <ReactNotification/>
                <div className="App h-100 p-relative">
                    <Header sharedVar={this.state.sharedVar} updateShared={this.updateData}/>
                    <div className="d-flex">
                        <Sidebar sharedVar={this.state.sharedVar} updateShared={this.updateData} sendToUpload={this.uploadList} userRole={this.state.userRole}/>
                        {this.getRoutes()}
                    </div>
                </div>
            </Router>
        );
    }
}

export default withRouter(App);
