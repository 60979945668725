import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import './LoginForm.css';
//import { history } from '../../../core/helpers';
import AuthService from '../../../core/services/AuthService';

import logo  from '../../../assets/images/logos/logo_yupoints_w.png';
import icnMail  from '../../../assets/images/icons/icn_mail.png';
import icnPassword  from '../../../assets/images/icons/icn_password.png';

import Alert from '../../shared/alert/Alert';

class LoginForm extends Component {
    constructor() {
        super();

        this.state = {
            username: {
                touched: false,
                value: ''
            },
            password: {
                touched: false,
                value: ''
            },
            formErrors: {
                username: '',
                password: ''
            },
            usernameValid: false,
            passwordValid: false,
            formValid: false,
            invalidUser: false,
            invalidMsg:'',
            baseName: this.getBasename(),
            logged: false
        }

        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidMount() {
        this.checkIfLogged();
    }

    getBasename() {
        return window.location.hostname === 'localhost' ? '':'';
    }

    checkIfLogged() {
        let logged = false
        if(AuthService.isAuthenticated()) {

            logged = true
        }

        this.setState({logged});
    }

    checkWhereToRedirect() {
        return `/`;
    }

    submitForm() {
        let objEnvio = {
            username: this.state.username.value,
            password: this.state.password.value
        }
        AuthService.handleLogin(objEnvio).then((response) => {
            this.setState({invalidUser: false,invalidMsg: ""});
            this.setState({invalidMsg: ""});
            AuthService.setUser(response.data);
            //this.checkIfLogged();
            //window.location.reload();
            this.setState({logged: AuthService.isAuthenticated()})
        }, (error) => {
            this.setState({invalidUser: true});
            this.setState({invalidMsg: "El usuario y/o contraseña no son válidos"});
        });
    }

    handleUserInput(value,key) {
        this.setState(
            {[key]: { value: value, touched: true}},
            () => { this.validateField(key, value) }
        );
    }

    onKeyDown(event) {

        if (event.key === 'Enter' && this.state.formValid) {
            event.preventDefault();
            event.stopPropagation();
            this.submitForm();
        }
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let usernameValid = this.state.usernameValid;
        let passwordValid = this.state.passwordValid;
        let msg = '';
        switch(fieldName) {
            case 'username':
                usernameValid = true;
                if(!value.length) {
                    msg = 'Usuario es requerido';
                    usernameValid = false;
                } else if(value.length >= 0 && value.length < 4) {
                    msg = 'Longitud mínima de usuario es de 4 carácteres';
                    usernameValid = false;
                }
                fieldValidationErrors.username = msg;
                break;
            case 'password':
                passwordValid = true;
                if(!value.length) {
                    msg = 'Contraseña es requerida';
                    passwordValid = false;
                } else if(value.length >= 0 && value.length < 6) {
                    msg = 'Longitud mínima de contraseña es de 6 carácteres';
                    passwordValid = false;
                } else {

                }
                fieldValidationErrors.password = msg
                break;
            default:
                break;
        }
        this.setState({formErrors: fieldValidationErrors,
            usernameValid: usernameValid,
            passwordValid: passwordValid
        }, this.validateForm);
    }

    validateForm() {
        this.setState({formValid: this.state.usernameValid && this.state.passwordValid});
    }

    errorClass(type) {
        return(this.state.formErrors[type].length === 0 || !this.state[type].touched ? '' : 'has-error');
        //return(!error.touched && error.value.length === 0 ? 'no-error' : 'has-error');
    }

    render() {
        return (

            <div className="container-fuild m-0 h-100 Login-main-container">
                {this.state.logged && <Redirect to={this.checkWhereToRedirect()} />}
                <div className="row h-100 d-flex align-items-center justify-content-center">
                    <div className="login-form-container col-12 col-sm-4">
                        <div className="row m-0 d-flex align-items-center pb-5">
                            <div className="col-12 text-center pt-3 p-relative">
                                <img src={logo} alt="Yupoints logo" className="Login-logo"/>
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className="col-12 text-center pb-3">
                                <h4 className="font-color-fff">
                                    <strong >Bienvenido</strong>
                                </h4>
                            </div>
                        </div>

                        <div className="row m-0">
                            <div className="col-12">
                                <div className="form-group p-relative">
                                    <img src={icnMail} alt="icon mail" className="Login-icon-input"/>
                                    <input type="text"
                                        className={`Login-input form-control ${this.errorClass("username")}`}
                                        id="username"
                                        name="username"
                                        placeholder="Ingresa tu usuario"
                                        value={this.state.username.value}
                                        onChange={(event) => this.handleUserInput(event.target.value,'username')}/>
                                    <span className={`${this.errorClass("username")}`}>
                                        {this.state.formErrors.username}
                                    </span>

                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-group p-relative">
                                    <img src={icnPassword} alt="icon mail" className="Login-icon-input"/>
                                    <input type="password"
                                        className={`Login-input form-control ${this.errorClass("password")}`}
                                        id="password"
                                        name="password"
                                        placeholder="Ingresa tu contraseña"
                                        value={this.state.password.value}
                                        onChange={(event) => this.handleUserInput(event.target.value,'password')}
                                        onKeyDown={this.onKeyDown}/>
                                    <span className={`${this.errorClass("password")}`}>
                                        {this.state.formErrors.password}
                                    </span>
                                </div>
                            </div>

                            <div className="col-12 pt-3">
                                <div className="row m-0 d-flex justify-content-center">
                                    <div className="col-6">
                                        <button className="btn btn-default Login-submit-btn w-100"
                                            onClick={() => {this.submitForm()}}
                                            disabled={!this.state.formValid}>
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <Alert msg={`${this.state.invalidMsg}`} type="error" showAlert={`${this.state.invalidUser}`}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(LoginForm);
