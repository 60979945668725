import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import SvgIcon from '../../../shared/svg-icon/SvgIcon';

import './SidebarItems.css';

class SidebarItems extends Component {

    constructor(props) {
        super(props);

        this.state = {
            baseName: this.getBasename()
        }
        this.handleLinkClick = this.handleLinkClick.bind(this);
    }

    getBasename() {
        return window.location.hostname === 'localhost' ? '':'/';
    }

    handleLinkClick () {
        this.props.updateCloser()
    }
    
    render() {
        return (
            <li>
                <NavLink exact activeClassName="active" to={`${this.props.item.path}`} onClick={this.handleLinkClick}>
                    <div className="sidebar-div">
                        <SvgIcon srcIcon={this.props.item.icon} />
                        <div className="menu-title">
                            {this.props.item.title}
                        </div>
                    </div>
                </NavLink>
            </li>
        );
    }
}
export default SidebarItems;
