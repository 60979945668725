import axios from 'axios';

class Auth {
    constructor() {
        this.url= this.getApiUrl();
    }

    isAuthenticated() {
        let userData = this.getUser();
        if(userData) {
            return true;
        }
        return false;
    }

    async handleLogin(data) {
        let response = await axios.post(`${this.url}login`,data);
        return response;
    }

    setUser(data) {
        localStorage.setItem('token', JSON.stringify(data));
    }

    getUser() {
        return JSON.parse(localStorage.getItem('token'));
    }

    getToken() {
        return JSON.parse(localStorage.getItem('token'));
    }

    handleSignout() {
        localStorage.clear();
    }

    getApiUrl() {
        return process.env.REACT_APP_BASE_URL;
        // return window.location.hostname === 'localhost' ? 'http://localhost:3000/api/v1/':'https://api.icecodemedia.com/api/v1/';
    }
}
const AuthService = new Auth();
export default AuthService;
