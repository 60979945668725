import React, { Component } from 'react';
import './Header.css';
import logo from '../../../assets/images/logos/logo_yupoints.png';
import SvgIcon from '../../shared/svg-icon/SvgIcon';
import User from './user/User';

class Header extends Component {


    constructor(props) {
        super(props);
        this.state = {
            buttonClass: '',
            sideBarToggle: true
        }
        this.handleClick = this.handleClick.bind(this);
    }

    updateToggle() {
        this.props.updateShared(this.props.sharedVar ===  'toggle-on' ? 'toggle-off':'toggle-on');
    }

    handleClick(event) {
        this.setState({sideBarToggle: !this.state.sideBarToggle});
        this.updateToggle();
        this.toggleAnimation('active');
        setTimeout(() => {
            this.toggleAnimation('disable');
        },500);
    }

    toggleAnimation(type) {
        this.setState({buttonClass: type === 'active' ? 'btn-pulse-yt-active':''});
    }

    render() {
        return (
            <div className="Header-container">
                <div className="row m-0 h-100 d-flex align-items-center">
                    <div className="col text-left">
                        <button className={`navbar-toggler toggler-example btn-pulse-yt ${this.state.buttonClass}`}
                            id="toggler-btn"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent1"
                            aria-controls="navbarSupportedContent1"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={(event) => {this.handleClick(event)}}>
                            <span className="dark-blue-text">
                                <SvgIcon srcIcon="menu"/>
                            </span>
                        </button>
                        <img src={`${logo}`} className="Header-logo" alt="header logo"/>
                    </div>
                    <div className="col text-right max-width-75px">
                        <User/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header;
