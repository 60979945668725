import React, { Component } from 'react';

import { Modal } from 'react-bootstrap';

import './Dialog.css';

class Dialog extends Component {

    constructor(props) {
        super(props);
        this.sendToParent = this.sendToParent.bind(this);
        this.state = {
            data: ''
        }
    }

    sendToParent () {
        this.props.buttonCallBack("submit");
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Header closeButton/>

                <Modal.Body>
                    {
                        this.props.children ? this.props.children:("Contenido")
                    }
                </Modal.Body>

            <Modal.Footer>
                <div className="row m-0 d-flex justify-content-center w-100 py-2">
                    {
                        this.props.buttonShow && !this.props.isUploading ? (
                            <div className="row m-0 d-flex justify-content-center w-100">
                                <div className="col-4">
                                    <button type="button" className="btn-standard w-100 primary-bg toggle-content" onClick={this.sendToParent}>
                                        {this.props.buttonTitle}
                                    </button>
                                </div>
                            </div>):(null)
                    }
                </div>
            </Modal.Footer>
          </Modal>
        );
    }
}
export default Dialog;
