import React from "react";

const Info = ({
  style = {},
  fill = "#000",
  width = "24px",
  className = "kt-svg-icon",
  viewBox = "0 0 24 24"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
  >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect id="bound" x="0" y="0" width="24" height="24"/>
          <circle id="Oval-5" fill={fill} opacity="0.3" cx="12" cy="12" r="10"/>
          <rect id="Rectangle-9" fill={fill} x="11" y="10" width="2" height="7" rx="1"/>
          <rect id="Rectangle-9-Copy" fill={fill} x="11" y="7" width="2" height="2" rx="1"/>
      </g>
  </svg>

);

export default Info;
