import React, { Component } from 'react';

import SvgIcon from '../../svg-icon/SvgIcon';

import './TableSchedule.css';
import { ItemFilter } from '../../../../core/helpers';

class TableSchedule extends Component {

    constructor(props) {
        super(props);
        this.getActiveSchedules = this.getActiveSchedules.bind(this);
        this.listSchedules = this.listSchedules.bind(this);
        this.dynamicSort = this.dynamicSort.bind(this);
        this.state = {
            deviceInfo: props.deviceInfo || []
        }
        this.deviceInfo = props.deviceInfo || [];
        this.days = [
            {day:"Lunes"},
            {day:"Martes"},
            {day:"Miércoles"},
            {day:"Jueves"},
            {day:"Viernes"},
            {day:"Sábado"},
            {day:"Domingo"}
        ];
        this.schedules = [
            {
                startTime: { hour: "14", minutes: "00" },
                endTime: { hour: "15", minutes: "59" }
            },
            {
                startTime: { hour: "16", minutes: "00" },
                endTime: { hour: "20", minutes: "59" }
            },
            {
                startTime: { hour: "21", minutes: "00" },
                endTime: { hour: "01", minutes: "00" }
            }
        ];
    }

    dynamicSort(property) {
        let sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return (a,b) => {
            let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    getActiveSchedules(timeBase, day) {
        if(this.deviceInfo.length) {
            let items = ItemFilter(this.deviceInfo, 'day_week', day);
            if(items.length) {
                let hour = ItemFilter(items, 'start_time', timeBase);
                return hour.length ? true:false;
            }
        }
        return false;
    }

    listSchedules (day) {
        const list = this.schedules.map((schedule,index) => {
            let timeBase = `${schedule.startTime.hour}:${schedule.startTime.minutes}`;
            let value = this.getActiveSchedules(timeBase, day);
            let grayColor1 = "#d8d8d8";
            let grayColor2 = "#6F6F6F";
            let purpleColor1 = "#D4CEFF";
            let purpleColor2: "#7F60EA";
            let svgFill = value ? purpleColor2:grayColor2;
            let divStyles = {
                background: value ? purpleColor1:grayColor1,
                width: '15px',
                height: '15px'
            }
            let icon = value ? "check":"close";

            return (
                <div className="d-flex align-items-center pr-3" key={index}>
                    <div className="div-background-svg d-flex align-items-center" style={divStyles}>
                        <SvgIcon srcIcon={icon} width="15" height="15" fill={svgFill}/>
                    </div>
                    <span className="pl-1 font-size-10">
                        {`${schedule.startTime.hour}:${schedule.startTime.minutes}`} -
                        {` ${schedule.endTime.hour}:${schedule.endTime.minutes} hrs`}
                    </span>
                </div>
            )
        });
        return list;
    }
    /*<div className="d-flex align-items-center">
        {this.listSchedules(index+1)}
    </div>*/
    render() {
        const listItems = this.days.map((day, index) => {
            return (
                <div className="row m-0 mouse-pointer" key={index+1} onClick={() => {this.props.handleClick(index + 1)}}>
                    <div className="TableSchedule-container col-12 p-0">
                        <div className="row m-0 d-flex align-items-center h-100">
                            <div className="col pl-0">
                                <div className="TableSchedule-title-container">
                                    <div>
                                        <strong>
                                            {day.day}
                                        </strong>
                                    </div>

                                </div>
                            </div>
                            <div className="col-1 pl-0 d-flex align-items-center max-width-70px">
                                <button className="table-btn" id={index + 1} onClick={() => {this.props.handleClick(index + 1)}}>
                                    <SvgIcon srcIcon="edit" width="35" height="35" fill="#7F60EA"/>
                                    <span className="table-btn-span">Editar</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        });

        return (
            <div className="col-12 col-sm">
                {listItems}
            </div>
        );
    }
}

export default TableSchedule;
