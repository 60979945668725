import React, { Component } from 'react';

import './NotFound.css';

class NotFound extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-4 offset-sm-4">
                        <span>
                            404 NOT FOUND
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}

export default NotFound;
