import axios from 'axios';
import AuthService from './AuthService';

class Conn {

    constructor() {
        this.url = this.getApiUrl();
    }

    isAuthenticated() {
        let userData = this.getUser();
        if(userData) {
            return true;
        }
        return false;
    }

    getUserRole() {
        let userRole = this.getToken();

        if (userRole) {
            return userRole.role;
        }
        return ""
    }

    async handleLogin(data) {
        let response = await axios.post('/login',data);
        return response;
    }

    async post(path, data) {
        let headers = this.getHeaders(null);
        let response = await axios.post(`${this.url}${path}`,data,headers);
        return response;
    }

    async put(path, data) {
        let headers = this.getHeaders(null);
        let response = await axios.put(`${this.url}${path}`,data,headers);
        return response;
    }

    async get(data) {
        let headers = this.getHeaders(null);
        let response = await axios.get(`${this.url}${data}`,headers);
        return response;
    }

    async delete(data) {
        let headers = this.getHeaders(null);
        let response = await axios.delete(`${this.url}${data}`,headers);
        return response;
    }

    async uploadFile(data) {
        const formData = new FormData();
        formData.append('video',data.file[0]);
        formData.append('title',data.title);
        formData.append('autor',data.autor);
        if (data.tags.length) {
            data.tags.forEach((tag, i) => {
                formData.append('tags', tag)
            });
        }
        return  await axios.post(`${this.url}video`, formData, this.getHeaders('multipart/form-data'));
    }

    async uploadPromo(data) {
        const formData = new FormData();
        formData.append('video',data.file[0]);
        formData.append('title',data.title);
        formData.append('timelapse',data.timelapse);
        formData.append('start_time',data.start_time);
        formData.append('end_time',data.end_time);
        formData.append('days', JSON.stringify(data.days));
        formData.append('devices_id', JSON.stringify(data.devices_id));
        return  await axios.post(`${this.url}promolist/promo`, formData, this.getHeaders('multipart/form-data'));
    }

    getHeaders (contentType) {
        var event = new Event('percentCompleted');
        let token = AuthService.getToken() || {token:''};
        let headers = {
            headers: {
                'Authorization': token.token,
                'Content-Type': contentType || 'application/json'
            },
            onUploadProgress: (progressEvent) => {
                var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );

                if (percentCompleted <= 100) {
                    localStorage.setItem('percentCompleted', percentCompleted);
                    if (percentCompleted === 100) {
                        setTimeout(() => {
                            localStorage.removeItem('percentCompleted');
                            window.dispatchEvent(event);
                        },20);
                        setTimeout(() => {
                            window.removeEventListener('percentCompleted', ()=>{});
                        },20);
                    } else {
                        window.dispatchEvent(event);
                    }
                    //console.log(localStorage.getItem('percentCompleted'));
                }

            }
        }
        return headers;
    }

    async addPlaylist(data,isEdit) {
		try {
            let headers = this.getHeaders(null);
			let playListInfo;
			let playlist_id = '';
			if(isEdit) {
				playlist_id = data.playListInfo.id;
				playListInfo = await axios.put(`${this.url}playlist/${playlist_id}`,data.playListInfo,headers);
			} else {
				playListInfo = await axios.post(`${this.url}playlist`,data.playListInfo,headers);
			}

			let response = {
				playListInfo
			};
			return response;
		} catch (error) {
			throw error;
		}
	}

    async addVideos(data,isEdit) {
		try {
            let headers = this.getHeaders(null);
			let videoInfo = await axios.post(`${this.url}playlist/${data.id}/videos`, data, headers);

			let response = {
				videoInfo
			};
			return response;
		} catch (error) {
			throw error;
		}
	}

    getApiUrl() {
        return process.env.REACT_APP_BASE_URL;
        // return window.location.hostname === 'localhost' ? 'http://localhost:3000/api/v1/':'https://api.icecodemedia.com/api/v1/';
    }
}
const ConnectionsService = new Conn();

export default ConnectionsService;
