import React, { Component } from 'react';

import {
    Airplay,
    Bulb,
    Check,
    Clock,
    Close,
    Display,
    Edit,
    Filter,
    Info,
    Left,
    Loading,
    Menu,
    Movielane,
    Music,
    Play,
    Playlist,
    Playmusic,
    Reload,
    Search,
    Signout,
    Upload
} from '../../../assets/svg/';

class SvgIcon extends Component{
    constructor(props){
        super();
        this.state = {
            style : props.style || {},
            fill :  props.fill || "blue",
            width : props.width || "24px",
            className : props.className || "kt-svg-icon",
            viewBox : props.viewBox || "0 0 24 24"
        }
    }
    render() {
        switch(this.props.srcIcon) {
            case 'airplay':
                return <Airplay {...this.state}/>;
            case 'bulb':
                return <Bulb {...this.state}/>;
            case 'check':
                return <Check {...this.state}/>;
            case 'clock':
                return <Clock {...this.state}/>;
            case 'close':
                return <Close {...this.state}/>;
            case 'display':
                return <Display {...this.state}/>;
            case 'edit':
                return <Edit {...this.state}/>;
            case 'filter':
                return <Filter {...this.state}/>;
            case 'info':
                return <Info {...this.state}/>;
            case 'left':
                return <Left {...this.state}/>;
            case 'loading':
                return <Loading {...this.state}/>;
            case 'menu':
                return <Menu {...this.state}/>;
            case 'movielane':
                return <Movielane {...this.state}/>;
            case 'music':
                return <Music {...this.state}/>;
            case 'play':
                return <Play {...this.state}/>;
            case 'playlist':
                return <Playlist {...this.state}/>;
            case 'playmusic':
                return <Playmusic {...this.state}/>;
            case 'reload':
                return <Reload {...this.state}/>;
            case 'search':
                return <Search {...this.state}/>;
            case 'signout':
                return <Signout {...this.state}/>;
            case 'upload':
                return <Upload {...this.state}/>;
            default:
                return null;
        }
    }
}

export default SvgIcon;
