import React from "react";

const Music = ({
  style = {},
  fill = "#000",
  width = "24px",
  className = "kt-svg-icon",
  viewBox = "0 0 24 24"
}) => (
    <svg width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1">

        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <path d="M8,17.0400072 L8,8 C8,7.56261503 8.28424981,7.17598102 8.70172501,7.04552002 L17.701725,4.54552002 C18.3456556,4.34429171 19,4.82535976 19,5.5 L19,17 C19,17.0001911 18.9999999,17.0003822 18.9999998,17.0005733 C18.9996127,18.1048793 17.880473,19 16.5,19 C15.1192881,19 14,18.1045695 14,17 C14,15.8954305 15.1192881,15 16.5,15 C16.6712329,15 16.838445,15.0137721 17,15.0400072 L17,8 L10,9.875 L10,19 C10,19.0001911 9.99999995,19.0003822 9.99999984,19.0005733 C9.99961272,20.1048793 8.88047301,21 7.5,21 C6.11928813,21 5,20.1045695 5,19 C5,17.8954305 6.11928813,17 7.5,17 C7.67123292,17 7.83844503,17.0137721 8,17.0400072 Z" fill={fill} fillRule="nonzero"></path>
        </g>

    </svg>
);

export default Music;
