import React, { Component } from 'react';
import './Devices.css';
import ConnectionsService from '../../../core/services/ConnectionsService';
import AuthService from '../../../core/services/AuthService';

import { ItemFilter } from '../../../core/helpers';
import { Redirect } from 'react-router-dom';

import Searcher from '../../shared/searcher/Searcher';
import ListBox from '../../shared/list-box/ListBox';
import Dialog from '../../shared/dialog/Dialog';
import TableSchedule from '../../shared/table/table-schedule/TableSchedule';

import defaultThumbnail from '../../../assets/images/icons/icn_video_default_thumbnail.png';
import ReactPaginate from 'react-paginate';

import swal from '@sweetalert/with-react';
import { store } from 'react-notifications-component';

class Devices extends Component {

    constructor(props) {
        super(props);
        this.state = {
            devices: [],
            deviceInfo: [],
            activeId: '',
            activeGo: false,
            goId:'',
            showPlayer: false,
            devicePlaying: {},
            deviceLog: [],
            perPage: 30,
            currentPage: 0,
            pageCount: 0,
            totalLogs: 0
        }
        this.deviceInfo = [];
        this.activeId = '';
        this.getDevices = this.getDevices.bind(this);
        this.listClicked = this.listClicked.bind(this);
        this.dynamicSort = this.dynamicSort.bind(this);
        this.handleSignout = this.handleSignout.bind(this);
        this.showPlayingModal = this.showPlayingModal.bind(this);
        this.reloadDevice = this.reloadDevice.bind(this);
        this.hidePlayer = this.hidePlayer.bind(this);
        this.getDeviceLog = this.getDeviceLog.bind(this);
        this.goEdit = this.goEdit.bind(this);
        this.handlePageClick = this.handlePageClick.bind(true);
        this.intervalId  = null;

        this.notificationConfig = {
            title: "Atención!",
            message: '',
            type: "warning",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 1000,
                onScreen: true
            }
        };
    }

    componentDidMount() {
        this.getDevices("");
    }

    showPlayingModal(itemId) {
        let device = ItemFilter(this.state.devices, 'itemId', itemId);

        if (device.length) {
            this.getDeviceLog(itemId, (result) => {
                if (result) {
                    this.setState({devicePlaying: device[0]},() => {
                        this.setState({showPlayer: true});
                        this.intervalId  = setInterval(this.getDeviceLog, 60000);
                    })
                }
            })
        }
    }

    reloadDevice(itemId) {
        swal({
            title: "¿Estás seguro?",
            text: "Estás a punto de reiniciar un dispositivo que está en funcionamiento",
            icon: "warning",
            buttons: ["No reiniciar!", "Reiniciar!"],
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                ConnectionsService.get(`device/${itemId}/reboot`)
                    .then((response) => {
                        this.notificationConfig.type = 'success';
                        this.notificationConfig.message = 'Dispositivo reiniciado correctamente';
                        store.addNotification(this.notificationConfig);
                    }, (e) => {
                        this.notificationConfig.type = 'danger';
                        this.notificationConfig.message = 'Error al intantar reiniciar dispositivo';
                        store.addNotification(this.notificationConfig);
                    });
            }
        });
    }

    handlePageClick = (data) => {
        this.setState({ currentPage: data.selected }, () => {
            this.getDeviceLog();
        });
    }

    hidePlayer() {
        clearInterval(this.intervalId);
        this.setState({
            showPlayer: false,
            devicePlaying: {},
            deviceLog: []
        });
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }

    }

    getDevices(text) {
        this.setState({devices:[]});
        let searchTerm = text.trim() !== "" ? `?s=${text}`:'';
        ConnectionsService.get(`device${searchTerm}`).then((response) => {
            if(response.data && response.data.length) {
                response.data.forEach((device) => {
                    device.itemId = device.id.toString();
                });
            }
            const devices = response.data.map((obj) => {
                obj['status'] = obj['socket_id'] ? true:false
                return obj;
            });
            if(devices.length) {
                this.activeId = devices[0].itemId;
            }
            this.setState({devices});
            this.getDeviceInfo("");
        }, (error) => {
            if(error.response.data) {
                if(!error.response.data.auth){
                    this.handleSignout();
                }
            }
        });
    }

    handleSignout() {
        AuthService.handleSignout();
        window.location.href = '/login';
    }

    getDeviceInfo(text) {
        this.deviceInfo = [];
        this.setState({deviceInfo:[]});
        let searchTerm = text.trim() !== "" ? `?s=${text}`:'';
        ConnectionsService.get(`device/${this.activeId}/playlists${searchTerm}`).then((response) => {
            if(response.data && response.data.length) {
                response.data.forEach((device) => {
                    device.itemId = device.id.toString();
                });
            }
            let deviceInfo = response.data.map(obj => obj);
            deviceInfo.sort(this.dynamicSort('day_week'));
            deviceInfo = this.fixSchedules(deviceInfo);
            this.deviceInfo = deviceInfo || [];
            this.setState({deviceInfo});
        }, error => console.log(error));
    }

    fixSchedules(obj) {
        let array = [];
        if(obj.length) {
            for(let i = 1; i <= 7; i++) {
                let items = ItemFilter(obj, 'day_week', i);
                if(items.length) {
                    let hour1 = ItemFilter(items, 'start_time', "14:00");
                    let hour2 = ItemFilter(items, 'start_time', "16:00");
                    let hour3 = ItemFilter(items, 'start_time', "21:00");

                    if(hour1.length) {
                        array.push(hour1[0]);
                    }
                    if(hour2.length) {
                        array.push(hour2[0]);
                    }
                    if(hour3.length) {
                        array.push(hour3[0]);
                    }
                }
            }
        }
        return array;
    }

    listClicked (id) {
        this.activeId = id.toString();
        this.setState({activeId: id});
        this.getDeviceInfo("");
    }

    dynamicSort(property) {
        let sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return (a,b) => {
            let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    getDeviceLog(id, callBack) {
        let idDevice = id ? id:this.state.devicePlaying.itemId;
        ConnectionsService.get(`device/${idDevice}/log?page=${this.state.currentPage + 1}`).then((response) => {
            let pageCount = 0;
            let totalLogs = 0;

            if (response.status === 200 && response.data.log.length) {
                if (response.data.total_items > 0){
                    pageCount = Math.ceil(response.data.total_items / this.state.perPage)
                    totalLogs = response.data.total_items;
                }
                if (response.data.log.length) {
                    response.data.log = response.data.log.map((log) => {
                        if (!log.tags) {
                            log['tags'] = [];
                        }
                        return log;
                    });
                }
                response.data.log = response.data.log.filter((log) => {

                    return log['video'];
                });
                this.setState({deviceLog: response.data.log, pageCount, totalLogs},() => {
                    if (callBack) {
                        callBack(true);
                    }
                });
            } else {
                this.setState({pageCount, totalLogs},() => {
                    if (callBack) {
                        callBack(false);
                    }
                });
            }
        });
    }

    goEdit(value) {
        this.setState({activeGo: true,goId:`${this.activeId}/${value}`});
    }

    render() {

        const showList = this.state.deviceLog.length ? this.state.deviceLog.map((log, index) => {
            return (
                <div className={`row m-0 p-2 ${index === 0 && this.state.currentPage === 0 ? 'on-playing-row':''}`} key={index}>
                    <div className="col-12 p-0">
                        <div className='row m-0 pb-2'>
                            <div className="col max-width-50px p-2">

                                <img src={defaultThumbnail} alt="" width="50"/>
                            </div>
                            <div className="col pr-0">
                                {
                                    log.video && log.video.title &&
                                    <span className="font-size-12 playing-title">{log.video.title} { log.playlist && log.playlist.name ? `(Lista: ${log.playlist.name})`:null}</span>
                                }
                                { index === 0 && this.state.currentPage === 0 && (<span className="font-size-10 font-color-fff"><br/>Reproduciendo</span>)} <br/>
                                {
                                    log.video && log.video.tags && log.video.tags.length ? log.video.tags.map(tag => <span className="Devices-tag-chip">{tag.name}</span>):(null)
                                }
                            </div>
                            <div className="col-4 pr-0">
                                <span className={`font-size-10 ${index === 0 && this.state.currentPage === 0 ? 'font-color-fff':''}`}>
                                    Número de pista: {log.num_track}
                                </span>
                                <br/>
                                <span className={`font-size-10 ${index === 0 && this.state.currentPage === 0 ? 'font-color-fff':''}`}>
                                    Total track: {log.total_track}
                                </span>
                                <br/>
                                <span className={`font-size-10 ${index === 0 && this.state.currentPage === 0 ? 'font-color-fff':''}`}>
                                    Fecha de reproducción: {log.created_at}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }):(null);

        return (
            <div className="Devices-container">

                <Dialog show={this.state.showPlayer}
                    onHide={() => this.hidePlayer()}
                    buttonTitle="Subir video"
                    buttonShow={false}
                    isUploading={false}>


                    <div className="row m-0">
                        <div className="col-12 pt-3">
                            <h4>{this.state.devicePlaying.name}</h4>
                            <hr/>
                            <div className="row m-0 log-container">
                                <div className="col-12 p-0">
                                    {showList}
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.totalLogs > this.state.perPage ? (
                        <ReactPaginate previousLabel={'Anterior'}
                            nextLabel={'Siguiente'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={this.state.pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}/>
                    ):(null)}

                </Dialog>


                {
                    this.state.activeGo && <Redirect to={`/dispositivos-edit/${this.state.goId}`} />
                }
                <div className="row m-0 pb-4">
                    <div className="col-12 col-md p-0 w-100 d-flex align-items-center">
                        <h4 className="mb-0">
                            Dispositivos
                        </h4>
                    </div>
                    <div className="col-12 col-md-3">
                        <Searcher updateInput={this.getDevices} placeholder="Buscar dispositivo"/>
                    </div>
                </div>

                <div className="row m-0 Devices-box">
                    <div className="col-12 col-sm-4">
                        {
                            this.state.devices.length ?
                                <ListBox items={this.state.devices}
                                    activeId={this.activeId}
                                    updateList={this.listClicked}
                                    handleClick={this.showPlayingModal}
                                    handleReload={this.reloadDevice}/>:(null)
                        }
                        {
                            !this.state.devices.length ?
                                <ListBox items={this.state.devices}
                                    activeId={"-1"}
                                    updateList={this.listClicked}
                                    handleClick={this.showPlayingModal}
                                    handleReload={this.reloadDevice}/>:(null)
                        }
                    </div>
                    {
                        this.deviceInfo.length ?
                            <TableSchedule deviceInfo={this.deviceInfo}
                                handleClick={this.goEdit}/>:(null)
                    }
                    {
                        !this.deviceInfo.length ?
                            <TableSchedule deviceInfo={this.deviceInfo}
                                handleClick={this.goEdit}/>:(null)
                    }
                </div>
            </div>
        );
    }
}
export default Devices;
