import React, { Component } from 'react';

import './LoadingBox.css';

import LottieBox from '../lottie-box/LottieBox';

class LoadingBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            animationType: this.props.animationType,
            boxWidth:'350',
            show: false
        };
        this.setupWindowSize = this.setupWindowSize.bind(this);
        this.useWindowSize = this.useWindowSize.bind(this);
    }

    componentDidMount() {
        this.setupWindowSize();
        this.useWindowSize();
    }

    setupWindowSize() {
        this.setState({show: false}, () => {
            let container = document.getElementsByClassName('LoadingBox-container');
            if (container.length) {
                let boxWidth = container[0].clientHeight / 2
                this.setState({boxWidth, show:true});
            }
        });
    }

    useWindowSize() {
        window.addEventListener('resize', this.setupWindowSize);
    }


    render() {

        return (
            <div className="LoadingBox-container p-5">
                { this.state.show ? (
                    <LottieBox animation = { this.state.animationType }
                        width = { this.state.boxWidth }
                        height = { this.state.boxWidth }
                    />
                ):(null)}
            </div>
        );
    }
}

export default LoadingBox;
