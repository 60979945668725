export const ParamsBuilder = (parameters) => {
    var paramString = '';

    if (parameters.length) {
        parameters.forEach((param, i) => {

            let key = Object.keys(param);

            if (key.length) {
                paramString += `${i === 0 ? '?':'&'}${key[0]}=${param[key[0]]}`;
            }
        });
    }
    
    return paramString;
}
